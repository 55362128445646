import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import base64 from 'base-64'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {AuthLoginApple, AuthLoginGoogle} from 'pages'
import {useHistory} from 'utils'
import {requestData} from 'services'
import {useWindowMode} from 'windows'
import {Avatar, Button, Icon, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {TemplateAuthAccessModalProps} from './TemplateAuthAccessModalProps'

const StyledModal = styled.div<React.CSSProperties>`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  transition: all 0.5s ease-in-out;
  z-index: 10;
`

const StyledDesktopModal = styled(Modal)`
  width: ${convertUnit(375)};
  padding: ${convertUnit(25)};
`

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    padding: ${convertUnit(25)};
  }
`

const StyledIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const StyledCloseIcon = styled.div`
  cursor: pointer;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(5)};
  width: 100%;
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledSSOContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
  width: 100%;
  margin-top: ${convertUnit(20)};
`

const StyledDescription = styled.div`
  margin-top: ${convertUnit(10)};
  margin-bottom: ${convertUnit(15)};
`

const StyledFooter = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledLastSavedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${convertUnit(15)};
  gap: ${convertUnit(8)};
  justify-content: center;
  align-items: center;
`

export default function TemplateAuthAccessModal({
  visible,
  toggleModal,
}: TemplateAuthAccessModalProps) {
  const {translate} = useTranslation()
  const {username, photo, refreshToken} = useSelector('lastUserState') || {}
  const history = useHistory()
  const mode = useWindowMode()
  const [loading, setLoading] = useState(false)

  const handleRenderCloseIcon = useMemo(
    () => (
      <StyledIconContainer>
        <StyledCloseIcon>
          <Icon
            type="close"
            size={convertUnit(20)}
            onClick={toggleModal}
            color="primary_5"
          />
        </StyledCloseIcon>
      </StyledIconContainer>
    ),
    [toggleModal],
  )

  const handleRenderHeader = useMemo(
    () => (
      <Paragraph fontSize="xl" fontWeight="bold">
        {translate('global:login')}
      </Paragraph>
    ),
    [translate],
  )

  const handlePressLogin = useCallback(() => {
    const user = 'web'
    const password = process.env.AUTH_CLIENT_PASSWORD
    if (refreshToken) {
      setLoading(true)
      requestData('auth_refresh_token', {
        data: {
          code_verifier: process.env.AUTH_CODE_VERIFIER,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        },
        headers: {
          Authorization: `Basic ${base64.encode(`${user}:${password}`)}`,
        },
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            toggleModal()
          }
        },
      })
    }
  }, [refreshToken, toggleModal])

  const handleRenderSavedLogin = useMemo(
    () =>
      username &&
      photo &&
      refreshToken && (
        <StyledLastSavedContainer>
          <Avatar src={photo} alt={photo} size={72} />
          <Button
            color="primary_5"
            backgroundColor="white_3"
            label={translate('auth:loginAs', {username})}
            onClick={handlePressLogin}
            disabled={loading}
          />
        </StyledLastSavedContainer>
      ),
    [handlePressLogin, loading, photo, refreshToken, translate, username],
  )

  const handleRenderButtons = useMemo(
    () => (
      <>
        <StyledDescription>
          <Paragraph fontSize="s" color="gray_5">
            {translate('forkygram:authAccessDescription')}
          </Paragraph>
        </StyledDescription>
        {handleRenderSavedLogin}
        <StyledButtonsContainer>
          <StyledButton
            backgroundColor="white_3"
            backgroundHoverColor="white_2"
            color="primary_5"
            label={translate('global:signUp')}
            onClick={() => history.push('auth_easy_signup', {})}
          />
          <StyledButton
            label={translate('global:login')}
            onClick={() => {
              const {pathname, search} = history.location
              if (history.location.pathname === '/') {
                history.push('auth_login', {})
              } else {
                history.pushQuery({
                  path: 'auth_login',
                  state: undefined,
                  queryObject: {
                    next: encodeURIComponent(`${pathname}${search}`),
                  },
                })
              }
            }}
          />
        </StyledButtonsContainer>
        <StyledSSOContainer>
          <AuthLoginGoogle
            label={translate('auth:registerGoogleButtonLabel')}
          />
          <AuthLoginApple label={translate('auth:registerAppleButtonLabel')} />
        </StyledSSOContainer>
        <StyledFooter>
          <Paragraph fontSize="s" color="gray_5">
            {translate('auth:identifierOneAccount')}
          </Paragraph>
        </StyledFooter>
      </>
    ),
    [handleRenderSavedLogin, history, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        {handleRenderCloseIcon}
        {handleRenderHeader}
        {handleRenderButtons}
      </StyledContainer>
    ),
    [handleRenderButtons, handleRenderCloseIcon, handleRenderHeader],
  )

  const handleRenderNonDesktop = useMemo(
    () => (visible ? <StyledModal>{handleRenderContent}</StyledModal> : null),
    [handleRenderContent, visible],
  )

  return mode === 'website' ? (
    <StyledDesktopModal visible={visible}>
      {handleRenderContent}
    </StyledDesktopModal>
  ) : (
    handleRenderNonDesktop
  )
}
