import React, {useCallback, useMemo, useState, useRef, useEffect} from 'react'
import {useParams} from 'react-router'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import heic2any from 'heic2any'
import {
  FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE,
  FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE,
  FORKYGRAM_UPLOAD_MAX_RESOLUTION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH,
  FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION,
  FORKYGRAM_UPLOAD_TYPE,
  IMAGE_ASSET,
  TREE_CHAT_SCROLL_THRESHOLD,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  FototreeUserType,
  TreeFototreeCommentResponse,
  TreeFotoTreeDetailResponse,
  TreeFotoTreeLeafResponse,
  TreeGlobalChatOverlayType,
} from 'types'
import {
  getThemeColor,
  showModalLimitedAccess,
  showModalReprocessKyc,
  showSnackbar,
  useDidMount,
  useDidUpdate,
  useHistory,
  useLocation,
} from 'utils'
import {
  Button,
  Icon,
  Image,
  Modal,
  ModalFullscreen,
  Paragraph,
} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateAuthAccessModal} from 'pages'
import animationData from 'assets/lottie/tree_loading.json'
import animationBackgroundData from 'assets/lottie/tree_background.json'
import {GiftShopTemplateShareModal} from '../../../gift-shop'
import {
  FototreeDetailContent,
  FototreeGlobalChat,
  FototreeOptionsMenu,
  TreeCongratulationModal,
  TreeFototreeIntroModal,
} from '../containers'

interface StyledInnerContainerProps {
  isHasData: boolean
}

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(70)};
  height: ${convertUnit(70)};
`

const StyledRootContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.black};
  box-sizing: border-box;
  position: relative;
  margin: auto;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const StyledInnerContainer = styled.div<StyledInnerContainerProps>`
  display: block;
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  max-width: ${convertUnit(600)};
  margin: ${({isHasData}) => `${convertUnit(isHasData ? -20 : 64)} auto 0`};
`

const StyledAbsoluteContainer = styled(StyledRowContainer)`
  position: fixed;
  width: 100%;
  max-width: ${convertUnit(600)};
  box-sizing: border-box;
  top: ${convertUnit(20)};
  margin: auto;
  justify-content: space-between;
  z-index: 3;
`

const StyledGeneralHeaderContainer = styled(StyledAbsoluteContainer)`
  width: fill-available;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: auto;
    right: ${convertUnit(20)};
    left: ${convertUnit(20)};
  }
`

const StyledHeaderContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(20)};
  align-items: center;
`

interface StyledRoundedIconProps {
  activeColor?: boolean
  hasLabel?: boolean
}

const StyledRoundedIcon = styled.div<StyledRoundedIconProps>`
  ${({hasLabel}) =>
    hasLabel
      ? {alignItems: 'center', padding: `0 ${convertUnit(16)}`}
      : {width: convertUnit(42)}}
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertUnit(42)};
  gap: ${convertUnit(6)};
  background-color: ${({theme, activeColor}) =>
    activeColor ? theme.primary_5 : getThemeColor(theme, 'black', 0.4)};
  border-radius: ${convertUnit(28)};
  cursor: pointer;
`

const StyledBottomButtonContainer = styled(StyledAbsoluteContainer)`
  width: fill-available;
  top: unset;
  bottom: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: auto;
    right: ${convertUnit(20)};
    left: ${convertUnit(20)};
  }
`

const StyledModalButtonContainer = styled(StyledRowContainer)`
  justify-content: space-between;
`

const StyledModalFullscreen = styled(ModalFullscreen)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledModal = styled(Modal)`
  padding: ${convertUnit(20)};
  width: 100%;
  max-width: ${convertUnit(335)};
  text-align: center;
`

const StyledButton = styled(Button)`
  min-width: ${convertUnit(138)};
`

const StyledUploadInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
`

const StyledLottieLoading = styled(Lottie)`
  height: 175px;
`

const StyledLottieBackgound = styled(Lottie)`
  position: fixed;
  height: 100%;
  width: 100%;
  margin-left: ${convertUnit(-20)};
  margin-top: ${convertUnit(180)};
  background: radial-gradient(40% 75% at 50% 30%, #3c5366 0%, #06080b 55%);
  transform: scale(1.8);

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(50)};
    transform: scale(2.6);
  }

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-top: 0;
    transform: scale(4);
  }
`

const StyledTopRightContainer = styled(StyledRowContainer)`
  gap: ${convertUnit(8)};
`

export default function TreeFototreeDetailScreen() {
  const illusImage = IMAGE_ASSET('giftshop', 'farmer-illustration.png')

  const history = useHistory()
  const {alias}: {alias: string} = useParams()
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const {id: userId, access_token} = useSelector('user') || {}
  const {videoIntro, footprintCount} = useSelector('lastTreeState')
  const {treeId: treeIdLoc, treeAlias, isPastMemory = false} = useLocation(
    'tree_fototree_detail',
  ).state
  const uploadRef = useRef<HTMLInputElement>(null)
  const [result, setResult] = useState<TreeFotoTreeDetailResponse>()
  const stateToggleMenu = useState(false)
  const [selectMode, setSelectMode] = useState(false)
  const [toggleModal, setToggleModal] = useState(false)
  const stateSelectedItem = useState<TreeFotoTreeLeafResponse[]>([])
  const stateData = useState<ReadonlyArray<TreeFotoTreeLeafResponse>>([])
  const stateGlobalChatData = useState<
    ReadonlyArray<TreeFototreeCommentResponse>
  >([])
  const stateOverlayGlobalChat = useState<TreeGlobalChatOverlayType>('hidden')
  const [data, setData] = stateData
  const setToggleMenu = stateToggleMenu[1]
  const [selectedItem, setSelectedItem] = stateSelectedItem
  const [globalChatOverlay, setGlobalChatOverlay] = stateOverlayGlobalChat
  const globalChatData = stateGlobalChatData[0]
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: childStatus} = useSelector('parentalConsentState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const [introModal, setIntroModal] = useState(false)
  const [loading, setLoading] = useState(videoIntro)
  const stateLoginModal = useState(false)
  const [loginModal, setLoginModal] = stateLoginModal
  const [pastMemory, setPastMemory] = useState(isPastMemory)
  const [shareModal, setShareModal] = useState(false)
  const [treeId, setTreeId] = useState(treeIdLoc || '')
  const [congratulationModal, setCongratulationModal] = useState(false)
  const [isUserScroll, setIsUserScroll] = useState(false)

  const userType: FototreeUserType = useMemo(() => {
    if (result?.owner_id === userId) return 'owner'
    if (userId && result?.farmers.includes(userId)) return 'farmer'
    return 'visitor'
  }, [result?.farmers, result?.owner_id, userId])

  const handleChackTreeExist = useCallback(() => {
    if (alias !== treeAlias) {
      if (!history.location.key && history.action === 'POP') {
        update('lastTreeState', {videoIntro: true})
      }
      setLoading(true)
      requestData('tree_get_fototree_check', {
        params: {alias},
        onRequestFailed: (reason) => {
          setLoading(false)
          showSnackbar(translate('global:messageError', {context: reason}))
        },
        onRequestSuccess: ({status, data: {result: detail}}) => {
          if (status === 200) {
            setTreeId(detail.id)
          } else if (status === 404) {
            history.replace('giftshop_page_not_found', {})
          } else {
            setLoading(false)
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
    }
  }, [alias, history, translate, treeAlias, update])

  const handleLoadTreeInfo = useCallback(() => {
    if (!history.location.key && history.action === 'POP') {
      update('lastTreeState', {videoIntro: true})
    }
    setLoading(true)
    requestData('tree_get_fototree_detail', {
      actionType: 'fetch',
      params: {tree_id: treeId},
      onRequestFailed: () => {
        setLoading(false)
      },
      onRequestSuccess: ({data: {result: fetchData, detail}, status}) => {
        if (status === 200) {
          setResult(fetchData)
          if (loading) {
            setIntroModal(
              fetchData.video_intro_url.length > 0 ||
                fetchData.video_animation_url.length > 0,
            )
          } else {
            !!footprintCount && setCongratulationModal(true)
          }
          update('lastTreeState', {videoIntro: false})
          setLoading(false)
        } else if (detail?.tree_id === 'invalid') {
          history.replace('giftshop_page_not_found', {})
        }
      },
    })
  }, [footprintCount, history, loading, setResult, treeId, update])

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const handleClickTreatment = useCallback(() => {
    setSelectedItem([])
    setSelectMode((prev) => !prev)
  }, [setSelectedItem])

  const handleClickShare = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      handleFeatureDisabled()
      return
    }
    setShareModal((prev) => !prev)
  }, [childStatus, handleFeatureDisabled])

  const handleClickEdit = useCallback(() => {
    history.push('tree_fototree_edit', {data: result, treeId}, alias)
  }, [alias, history, result, treeId])

  const handleClickManageFarmer = useCallback(() => {
    history.push('tree_fototree_manage_farmer', {tree_id: treeId}, alias)
  }, [alias, history, treeId])

  const handleClickPreviewQr = useCallback(() => {
    history.push(
      'tree_fototree_preview_qr',
      {treeId, treeName: result?.name},
      alias,
    )
  }, [alias, history, result?.name, treeId])

  const handleToggleModal = useCallback(
    () => setToggleModal((prev) => !prev),
    [],
  )

  const handleLeafAMemory = useCallback(() => {
    if (access_token) {
      if (
        childStatus === 'UNDERAGE' ||
        !(kycStatus === 'verified' || kycStatus === 'verified_check')
      ) {
        handleFeatureDisabled()
        return
      }
      uploadRef.current?.click()
    } else setLoginModal(true)
  }, [
    access_token,
    childStatus,
    handleFeatureDisabled,
    kycStatus,
    setLoginModal,
  ])

  const handleClickDelete = useCallback(() => {
    const leaf_ids = selectedItem.map((item) => item.post_id)
    requestData('tree_delete_leaf', {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {leaf_ids},
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setData(data.filter((el) => !selectedItem.includes(el)))
          showSnackbar(translate('giftShop:snackbarSuccessExecute'))
          handleClickTreatment()
        } else if (status === 403)
          showSnackbar(translate('giftShop:snackbarFailedExecute'))
        handleToggleModal()
      },
      onRequestFailed: () => {
        handleToggleModal()
        showSnackbar(translate('giftShop:snackbarFailedExecute'))
      },
    })
  }, [
    data,
    handleClickTreatment,
    handleToggleModal,
    selectedItem,
    setData,
    translate,
  ])

  const handleTogglePastMemory = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    if (childStatus === 'UNDERAGE') {
      handleFeatureDisabled()
      return
    }
    setPastMemory((prev) => !prev)
    history.replace(
      'tree_fototree_detail',
      {
        ...history.location.state,
        isPastMemory: !pastMemory,
      },
      alias,
    )
    window.location.reload()
  }, [
    access_token,
    alias,
    childStatus,
    handleFeatureDisabled,
    history,
    pastMemory,
    setLoginModal,
  ])

  const handleClickGlobalChat = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      handleFeatureDisabled()
      return
    }
    setGlobalChatOverlay('full')
  }, [childStatus, handleFeatureDisabled, setGlobalChatOverlay])

  const handleNavigateSearchBib = useCallback(() => {
    if (result) {
      if (!access_token) {
        setLoginModal(true)
        return
      }
      history.pushQuery({
        path: 'tree_events_bib_masonry',
        state: {
          tree_url: result.url,
          tree_name: result.name,
          fromScan: false,
        },
        queryObject: {
          tree_id: result.id,
          tag_id: result.tag_id,
          search: '',
        },
      })
    }
  }, [access_token, history, result, setLoginModal])

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement, UIEvent>) => {
      if (!globalChatData.length) return

      if (
        e.currentTarget.scrollTop > TREE_CHAT_SCROLL_THRESHOLD &&
        !isUserScroll
      ) {
        setIsUserScroll(true)
        setGlobalChatOverlay('hidden')
      } else if (
        e.currentTarget.scrollTop <= TREE_CHAT_SCROLL_THRESHOLD &&
        isUserScroll
      ) {
        setIsUserScroll(false)
        setGlobalChatOverlay('preview')
      }
    },
    [globalChatData.length, isUserScroll, setGlobalChatOverlay],
  )

  const handleNavigateBack = useCallback(() => {
    if (!history.location.key && history.action === 'POP') {
      history.push('giftshop_feed', {})
    } else {
      history.goBack()
    }
  }, [history])

  const handleRenderConfirmationModal = useMemo(
    () => (
      <StyledModal visible={toggleModal}>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('tree:trimmingLeaf')}
        </Paragraph>
        <Paragraph
          style={{marginTop: convertUnit(8)}}
          fontSize="l"
          fontWeight="regular">
          {translate('tree:trimmingLeafDescription')}
        </Paragraph>
        <StyledImage alt="farmer illustration" src={illusImage} />
        <StyledModalButtonContainer>
          <StyledButton
            backgroundColor="white_3"
            color="primary_5"
            label={translate('global:cancel')}
            fontSize="m"
            fontWeight="bold"
            onClick={handleToggleModal}
          />
          <StyledButton
            backgroundColor="primary_5"
            label={translate('global:yesImSure')}
            fontSize="m"
            fontWeight="bold"
            onClick={handleClickDelete}
          />
        </StyledModalButtonContainer>
      </StyledModal>
    ),
    [handleClickDelete, handleToggleModal, illusImage, toggleModal, translate],
  )

  const handleRenderOptionsMenu = useMemo(
    () =>
      access_token &&
      result && (
        <FototreeOptionsMenu
          status={userType}
          stateToggle={stateToggleMenu}
          onClickEdit={handleClickEdit}
          onClickManageFarmer={handleClickManageFarmer}
          onClickTreatment={handleClickTreatment}
          onClickPreview={handleClickPreviewQr}
          onClickShare={handleClickShare}>
          <StyledRoundedIcon>
            <Icon
              type="more-filled"
              size={convertUnit(20)}
              color="white_1"
              onClick={() => setToggleMenu((prev) => !prev)}
            />
          </StyledRoundedIcon>
        </FototreeOptionsMenu>
      ),
    [
      access_token,
      handleClickEdit,
      handleClickManageFarmer,
      handleClickPreviewQr,
      handleClickShare,
      handleClickTreatment,
      result,
      setToggleMenu,
      stateToggleMenu,
      userType,
    ],
  )

  const handleRenderHeaderTreatment = useMemo(
    () => (
      <StyledGeneralHeaderContainer>
        <StyledHeaderContainer>
          <StyledRoundedIcon>
            <Icon
              type="close"
              size={convertUnit(20)}
              color="white_1"
              onClick={handleClickTreatment}
            />
          </StyledRoundedIcon>
          <Paragraph color="white_1" fontSize="l" fontWeight="medium">
            {translate('tree:selected', {count: selectedItem.length})}
          </Paragraph>
        </StyledHeaderContainer>
        {selectedItem.length > 0 && (
          <StyledRoundedIcon>
            <Icon
              type="shears"
              size={convertUnit(20)}
              color="white_1"
              onClick={handleToggleModal}
            />
          </StyledRoundedIcon>
        )}
      </StyledGeneralHeaderContainer>
    ),
    [handleClickTreatment, handleToggleModal, selectedItem.length, translate],
  )

  const handleRenderHeaderGlobal = useMemo(
    () => (
      <StyledGeneralHeaderContainer>
        <StyledRoundedIcon>
          <Icon
            type="back"
            size={convertUnit(20)}
            color="white_1"
            onClick={handleNavigateBack}
          />
        </StyledRoundedIcon>
        <StyledTopRightContainer>
          <StyledRoundedIcon hasLabel onClick={handleNavigateSearchBib}>
            <Icon type="search" size={convertUnit(20)} color="white_1" />
            <Paragraph fontWeight="bold" color="white_1">
              {translate('giftShop:yourPhotos')}
            </Paragraph>
          </StyledRoundedIcon>
          <StyledRoundedIcon activeColor={pastMemory}>
            <Icon
              type="footprints"
              size={convertUnit(20)}
              color="white_1"
              onClick={handleTogglePastMemory}
            />
          </StyledRoundedIcon>
          <StyledRoundedIcon>
            <Icon
              type="message"
              size={convertUnit(20)}
              color="white_1"
              onClick={handleClickGlobalChat}
            />
          </StyledRoundedIcon>
          {handleRenderOptionsMenu}
        </StyledTopRightContainer>
      </StyledGeneralHeaderContainer>
    ),
    [
      handleClickGlobalChat,
      handleNavigateBack,
      handleNavigateSearchBib,
      handleRenderOptionsMenu,
      handleTogglePastMemory,
      pastMemory,
      translate,
    ],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledAbsoluteContainer>
        {selectMode ? handleRenderHeaderTreatment : handleRenderHeaderGlobal}
      </StyledAbsoluteContainer>
    ),
    [handleRenderHeaderGlobal, handleRenderHeaderTreatment, selectMode],
  )

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  const handleRenderShareModal = useMemo(
    () =>
      shareModal && (
        <GiftShopTemplateShareModal
          type="fototree"
          targetId={treeId}
          toggleModal={() => setShareModal(false)}
        />
      ),
    [shareModal, treeId],
  )

  const isTreeEventFuture = useMemo(
    () =>
      !!(
        result?.event_start_date &&
        result.event_start_date &&
        Date.now() / 1000
      ),
    [result?.event_start_date],
  )

  const handleRenderBottomButton = useMemo(
    () =>
      !selectMode && (
        <StyledBottomButtonContainer>
          <Button
            backgroundColor="white_3"
            backgroundHoverColor="white_1"
            color="primary_5"
            label={translate('tree:leafAMemory')}
            style={{width: '100%', boxShadow: '0px 2px 4px 0px #0000003D'}}
            disabled={isTreeEventFuture}
            onClick={handleLeafAMemory}
            leftIcon={
              <Icon
                size={convertUnit(16)}
                type="plus"
                color={isTreeEventFuture ? 'gray_3' : 'primary_5'}
                marginRight={convertUnit(8)}
              />
            }
          />
        </StyledBottomButtonContainer>
      ),
    [handleLeafAMemory, isTreeEventFuture, selectMode, translate],
  )

  const handleInputImage = useCallback(() => {
    if (uploadRef.current?.files?.length) {
      let img = uploadRef.current.files[0]

      const {size, name} = img
      let {type} = img

      if (type === '') {
        type = name.substring(name.lastIndexOf('.') + 1).toLowerCase() ?? ''
        FORKYGRAM_UPLOAD_TYPE.some((currentType) => {
          const typeMatchesWithExtension = currentType.includes(type)
          if (typeMatchesWithExtension) type = currentType
          return typeMatchesWithExtension
        })
      }

      const handlingSpecialImageType = FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE.includes(
        type,
      )
      const handlingSpecialVideoType = FORKYGRAM_SPECIAL_VIDEO_UPLOAD_TYPE.includes(
        type,
      )

      if (!FORKYGRAM_UPLOAD_TYPE.includes(type)) {
        showSnackbar(
          img.type.startsWith('image')
            ? translate('forkygram:uploadInvalidImageFormat')
            : translate('forkygram:uploadInvalidVideoFormat'),
        )
        return
      }
      if (type.startsWith('image') && size > FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE) {
        showSnackbar(translate('forkygram:uploadMaxImageSizeMessage'))
        return
      }
      if (!result?.tag_id) {
        showSnackbar(translate('global:messageError'))
        return
      }

      const reader = new FileReader()
      reader.onload = async () => {
        const imageUrl = reader.result?.toString()

        if (!imageUrl) return

        if (
          imageUrl?.includes('image') ||
          (type.includes('image') && handlingSpecialImageType)
        ) {
          const image = document.createElement('img')

          if (handlingSpecialImageType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE

            const newImage = await fetch(imageUrl)
              .then((res) => res.blob())
              .then((blob) =>
                heic2any({
                  blob,
                  toType: FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
                }),
              )
              .then((blobresult) => {
                image.src = URL.createObjectURL(blobresult as Blob)
                const finalName = name.replace(
                  /\.(.)+/g,
                  `.${
                    FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE.split('/')[1]
                  }`,
                )
                return new File([blobresult as Blob], finalName, {
                  type: FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
                })
              })
              .catch(() => {})

            img = newImage as File
          } else {
            image.src = imageUrl
          }

          image.onload = () => {
            const {width, height} = image

            if (width * height > FORKYGRAM_UPLOAD_MAX_RESOLUTION) {
              showSnackbar(
                translate('forkygram:uploadMaxImageResolutionMessage'),
              )
              return
            }
            if (
              width < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION ||
              height < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION
            ) {
              showSnackbar(
                translate('forkygram:uploadMinImageResolutionMessage'),
              )
              return
            }
            return history.replace(
              'tree_fototree_upload',
              {
                treeId,
                tagId: result.tag_id,
                file: img,
              },
              alias,
            )
          }
        }
        if (
          imageUrl?.includes('video') ||
          (type.includes('video') && handlingSpecialVideoType)
        ) {
          const vid = document.createElement('video')

          if (handlingSpecialVideoType) {
            type = FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE

            const finalName = name.replace(
              /\.(.)+/g,
              `.${FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE.split('/')[1]}`,
            )

            const newVideo = await fetch(imageUrl)
              .then((res) => res.blob())
              .then(
                (res) =>
                  new File([res], finalName, {
                    type: FORKYGRAM_CONVERTED_SPECIAL_VIDEO_UPLOAD_TYPE,
                  }),
              )

            vid.src = URL.createObjectURL(newVideo)
            img = newVideo as File
          } else {
            vid.src = imageUrl
          }

          vid.onloadedmetadata = () => {
            const {videoWidth, duration} = vid

            if (duration > FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION) {
              showSnackbar(translate('forkygram:uploadMaxVideoDurationMessage'))
              return
            }
            if (videoWidth > FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH) {
              showSnackbar(translate('forkygram:uploadMaxVideoWidthMessage'))
              return
            }
            return history.replace(
              'tree_fototree_upload',
              {
                treeId,
                tagId: result.tag_id,
                file: img,
              },
              alias,
            )
          }
        }
      }
      reader.readAsDataURL(img)
    }
  }, [alias, history, result?.tag_id, translate, treeId])

  const handleRenderInputFile = useMemo(
    () => (
      <StyledUploadInput
        type="file"
        ref={uploadRef}
        onChange={handleInputImage}
        accept={FORKYGRAM_UPLOAD_TYPE.join(',')}
      />
    ),
    [handleInputImage],
  )

  const handleRenderGlobalChat = useMemo(
    () =>
      treeId &&
      !selectMode &&
      childStatus !== 'UNDERAGE' && (
        <FototreeGlobalChat
          treeId={treeId}
          userType={userType}
          isUserScroll={isUserScroll}
          stateData={stateGlobalChatData}
          stateOverlay={stateOverlayGlobalChat}
          stateLoginModal={stateLoginModal}
        />
      ),
    [
      treeId,
      selectMode,
      childStatus,
      userType,
      isUserScroll,
      stateGlobalChatData,
      stateOverlayGlobalChat,
      stateLoginModal,
    ],
  )

  const handleRenderLoading = useMemo(
    () => (
      <StyledModalFullscreen style={{borderRadius: 0}} visible>
        <StyledLottieLoading animationData={animationData} />
      </StyledModalFullscreen>
    ),
    [],
  )

  const handleRenderCongratulationModal = useMemo(
    () =>
      result &&
      footprintCount && (
        <TreeCongratulationModal
          footprintCount={footprintCount}
          treeName={result?.name}
          onClose={() => {
            setCongratulationModal(false)
            update('lastTreeState', {footprintCount: undefined})
          }}
        />
      ),
    [footprintCount, result, update],
  )

  const handleRenderBackground = useMemo(
    () => <StyledLottieBackgound animationData={animationBackgroundData} />,
    [],
  )

  const handleRenderVideoIntro = useMemo(
    () =>
      result && (
        <TreeFototreeIntroModal
          result={result}
          visible={introModal}
          toggleModal={() => setIntroModal((prev) => !prev)}
        />
      ),
    [introModal, result],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledInnerContainer isHasData={!!stateData[0].length}>
        {handleRenderHeader}
        {treeId && (
          <FototreeDetailContent
            stateData={stateData}
            stateSelectedItem={stateSelectedItem}
            profileData={result}
            selectMode={selectMode}
            treeId={treeId}
            treeAlias={alias}
            isPastMemory={pastMemory}
            onScroll={handleScroll}
          />
        )}
        {handleRenderGlobalChat}
        {handleRenderBottomButton}
      </StyledInnerContainer>
    ),
    [
      alias,
      handleRenderBottomButton,
      handleRenderGlobalChat,
      handleRenderHeader,
      handleScroll,
      pastMemory,
      result,
      selectMode,
      stateData,
      stateSelectedItem,
      treeId,
    ],
  )

  useDidMount(handleChackTreeExist)

  useDidUpdate(() => {
    if (
      !isUserScroll &&
      !!globalChatData.length &&
      globalChatOverlay === 'hidden'
    ) {
      setGlobalChatOverlay('preview')
    }
  }, [globalChatData.length])

  useEffect(() => {
    if (treeId) handleLoadTreeInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeId])

  return (
    <StyledRootContainer>
      {loading && handleRenderLoading}
      {congratulationModal && handleRenderCongratulationModal}
      {handleRenderBackground}
      {handleRenderVideoIntro}
      {handleRenderInputFile}
      {handleRenderConfirmationModal}
      {handleRenderLoginModal}
      {handleRenderContent}
      {handleRenderShareModal}
    </StyledRootContainer>
  )
}
