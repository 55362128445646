import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {CredentialResponse, GoogleLogin} from '@react-oauth/google'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {translate} from 'i18n'
import {AuthRegisterWaitingModal} from 'pages'
import {requestData} from 'services'
import {showSnackbar, useHistory} from 'utils'
import {Button, IconGoogle, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {reduxUpdateDispatcher, useSelector} from 'lib/redux'
import {AuthFormSimpleRegisterData, AuthLoginResponse} from 'types'
import {TemplateAuthAccessUsernameModal} from '../../../../template'
import {AuthLoginGoogleProps} from './AuthLoginGoogleProps'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

const StyledButtonLoginGoogle = styled(Button)`
  ${({theme}) => ({borderColor: theme.white_3})}
  border-style: solid;
  border-width: ${convertUnit(1)};
  display: flex;
  flex: 1;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  width: 100%;
`

const StyledIconGoogle = styled(IconGoogle)`
  margin-right: ${convertUnit(10)};
`

const StyledGoogleLoginContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  border-radius: ${convertUnit(22)};
  top: ${convertUnit(0)};
`

const StyledGoogleLogin = styled(GoogleLogin)`
  width: 100%;
  border-radius: 50%;
`

const StyledLabel = styled(Paragraph)`
  text-align: center;
`
export default function AuthLoginGoogle({
  label,
  buttonStyle,
}: AuthLoginGoogleProps) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>()
  const {tag_id, tree_id} = useSelector('treeQrState') || {}
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {
      username: '',
    },
  })
  const {setError} = form
  const stateModalUsername = useState(false)
  const stateModalUsernameLoading = useState(false)
  const [ssoToken, setSsoToken] = useState('')

  const handleRenderWaitingModal = useMemo(
    () => (
      <AuthRegisterWaitingModal
        visible={modal}
        toggleModal={() => setModal(false)}
      />
    ),
    [modal],
  )

  const handleEasySignupLogin = useCallback(
    (tokenId: string, username?: string) => {
      if (username) {
        stateModalUsernameLoading[1](true)
      }
      requestData('auth_easy_signup_login_sso_google', {
        data: {token: tokenId, username},
        onRequestReceived: () => {
          stateModalUsernameLoading[1](false)
        },
        onRequestSuccess: ({data, status}) => {
          if (status === 200) {
            const userInfo = data.result as AuthLoginResponse
            reduxUpdateDispatcher('user', userInfo)
            if (tree_id && tag_id) {
              history.pushQuery({
                path: 'tree_events_bib_masonry',
                state: {
                  tree_url: '',
                  tree_name: '',
                  fromScan: true,
                },
                queryObject: {
                  tree_id,
                  tag_id,
                  search: '',
                },
              })
              return
            }
            const {search} = history.location
            if (search.startsWith('?next')) {
              if (history.length > 1) {
                history.goBack()
              } else {
                window.location.replace(decodeURIComponent(search))
              }
              return
            }
            location.reload()
          } else if (status === 202) {
            if (Array.isArray(data.result)) {
              history.push('auth_easy_signup_sso', {
                token: tokenId,
                ssoMethod: 'gosso',
                suggestions: data.result,
              })
            } else {
              history.push('auth_easy_signup_underage', {
                child_consent_code: data.result.child_consent_code,
                token: tokenId,
                method: 'gosso',
              })
            }
          } else if (status === 400) {
            if (data.detail && data.detail.username === 'invalid') {
              setError('username', {
                type: 'unavailable',
                message: translate('auth:registerEasySignupUsernameNotMatch'),
              })
            }
          } else if (status === 401) {
            stateModalUsername[1](true)
          } else if (status === 503) {
            setModal(true)
          }
        },
        onRequestFailed: () => {
          showSnackbar(translate('giftShop:snackbarFailedExecute'))
          setLoading(false)
        },
      })
    },
    [
      history,
      setError,
      stateModalUsername,
      stateModalUsernameLoading,
      tag_id,
      tree_id,
    ],
  )

  const handleSuccess = useCallback(
    (response: CredentialResponse) => {
      const {credential: tokenId} = response
      if (tokenId) {
        setSsoToken(tokenId)
        handleEasySignupLogin(tokenId)
      }
    },
    [handleEasySignupLogin],
  )

  const handleGoogleLogin = useMemo(
    () => (
      <StyledGoogleLogin
        cancel_on_tap_outside
        onSuccess={handleSuccess}
        shape="pill"
        width={`${width}px`}
      />
    ),
    [handleSuccess, width],
  )

  const handleRenderModalUsername = useMemo(
    () => (
      <TemplateAuthAccessUsernameModal
        stateVisible={stateModalUsername}
        stateLoading={stateModalUsernameLoading}
        form={form}
        onClick={(username) => handleEasySignupLogin(ssoToken, username)}
      />
    ),
    [
      form,
      handleEasySignupLogin,
      ssoToken,
      stateModalUsername,
      stateModalUsernameLoading,
    ],
  )

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth)
  }, [])

  return (
    <StyledContainer ref={ref}>
      {handleRenderWaitingModal}
      {handleRenderModalUsername}
      <StyledButtonLoginGoogle
        leftIcon={<StyledIconGoogle />}
        backgroundColor="white_3"
        color="primary_5"
        isLoading={loading}
        disabled={loading}
        style={buttonStyle}>
        <StyledLabel color="primary_5" fontSize="m" fontWeight="bold">
          {label}
        </StyledLabel>
      </StyledButtonLoginGoogle>
      <StyledGoogleLoginContainer>
        {handleGoogleLogin}
      </StyledGoogleLoginContainer>
    </StyledContainer>
  )
}
