import React, {useMemo} from 'react'
import {Layer, Source} from 'react-map-gl'
import {AnyPaint, Visibility} from 'mapbox-gl'
import {
  TREE_MAP_LAYER_FILTER_GENERATED_FOTOTREE,
  TREE_MAP_LAYER_ID_CREATOR_LIVE,
  TREE_MAP_LAYER_ID_FOTOTREE,
  TREE_MAP_LAYER_LAYOUT,
  TREE_MAP_LAYER_LAYOUT_CREATOR_LIVE,
  TREE_MAP_LAYER_LAYOUT_FOTOTREE,
} from 'consts'
import {useTheme} from 'themes'
import {FototreeMapPinpointProps} from './FototreeMapProps'

export default function FototreeMapPinpoint({
  activeFeatureData,
  cluster,
  creatorCluster,
  overlayFootprint,
  viewMode,
}: FototreeMapPinpointProps) {
  const theme = useTheme()

  const fototreeVisibility = useMemo<Visibility>(
    () =>
      viewMode !== 'creator' ||
      (viewMode === 'creator' && overlayFootprint !== 'hidden')
        ? 'visible'
        : 'none',
    [viewMode, overlayFootprint],
  )

  const creatorLiveVisibility = useMemo<Visibility>(
    () =>
      viewMode !== 'fototree' && overlayFootprint === 'hidden'
        ? 'visible'
        : 'none',
    [viewMode, overlayFootprint],
  )

  const mapStylePaint = useMemo<AnyPaint>(
    () => ({
      'text-color': [
        'match',
        ['get', 'category'],
        'event',
        theme.danger_5,
        'community',
        theme.success_7,
        theme.primary_5,
      ],
      'text-halo-width': 2,
      'text-halo-color': theme.white_1,
    }),
    [theme.danger_5, theme.primary_5, theme.success_7, theme.white_1],
  )

  const mapStylePaintCreator = useMemo<AnyPaint>(
    () => ({
      'text-color': theme.primary_7,
      'text-halo-width': 1,
      'text-halo-color': theme.gray_1,
    }),
    [theme.gray_1, theme.primary_7],
  )

  const handleRenderPinpoint = useMemo(
    () => (
      <Source
        id="map-source"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: cluster,
        }}>
        <Layer
          id={TREE_MAP_LAYER_ID_FOTOTREE}
          beforeId={
            creatorCluster.length ? TREE_MAP_LAYER_ID_CREATOR_LIVE : undefined
          }
          type="symbol"
          layout={{
            ...TREE_MAP_LAYER_LAYOUT,
            ...TREE_MAP_LAYER_LAYOUT_FOTOTREE,
            visibility: fototreeVisibility,
          }}
          paint={mapStylePaint}
          filter={[
            'all',
            ['has', 'alias'],
            [
              'all',
              ['!=', ['get', 'id'], activeFeatureData?.properties.id || ''],
            ],
            TREE_MAP_LAYER_FILTER_GENERATED_FOTOTREE,
          ]}
        />
      </Source>
    ),
    [
      activeFeatureData?.properties.id,
      cluster,
      creatorCluster,
      fototreeVisibility,
      mapStylePaint,
    ],
  )

  const handleRenderPinpointCreatorLive = useMemo(
    () => (
      <Source
        id="map-creator-source"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: creatorCluster,
        }}>
        <Layer
          id={TREE_MAP_LAYER_ID_CREATOR_LIVE}
          type="symbol"
          layout={{
            ...TREE_MAP_LAYER_LAYOUT,
            ...TREE_MAP_LAYER_LAYOUT_CREATOR_LIVE,
            visibility: creatorLiveVisibility,
          }}
          paint={mapStylePaintCreator}
        />
      </Source>
    ),
    [creatorCluster, creatorLiveVisibility, mapStylePaintCreator],
  )

  return (
    <>
      {handleRenderPinpoint}
      {handleRenderPinpointCreatorLive}
    </>
  )
}
