import {useCallback, useMemo} from 'react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {handleUploadSingleFileToWasabi} from 'utils/files'
import {showSnackbar} from 'utils/components'
import {
  ForkygramUploadPublishParam,
  ImageExtensionType,
  VideoExtensionType,
} from 'types'
import {useHistory} from 'utils/routes'
import {useSelector, useDispatch} from 'lib/redux'
import {
  FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE,
  FORKYGRAM_UPLOAD_MAX_RESOLUTION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION,
  FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE,
  FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH,
  FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION,
  FORKYGRAM_UPLOAD_TYPE,
} from 'consts'
import {useDidMount} from 'utils/objects'

export function useForkygramUpload({
  stateLoading,
  stateSource,
  form,
  data,
}: ForkygramUploadPublishParam) {
  const history = useHistory()
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const setLoading = stateLoading[1]
  const setSrc = stateSource[1]

  const handleRedirect = useCallback(() => {
    history.goBack()
  }, [history])

  const handleNavigateProfile = useCallback(() => {
    history.push('giftshop_profile', {self: true}, username)
  }, [history, username])

  const handleNavigateFotoTree = useCallback(() => {
    history.replace(
      'tree_fototree_detail',
      {
        treeId: data.treeId,
        treeAlias: data.treeAlias,
      },
      data.treeAlias,
    )
  }, [data.treeAlias, data.treeId, history])

  const getContentLink = useCallback(async () => {
    setLoading(true)
    const values = form.current?.getValues()
    if (values && values.file && values.extension) {
      const response = await requestData('forkygram_post_posts_link', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          extension: values.extension,
          width: values.width,
          height: values.height,
          size: values.size,
          tag_id: values.tag.id,
        },
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result.link
        : null
    }
    return null
  }, [form, setLoading])

  const publishToWasabi = useCallback(
    async (link: string) => {
      const values = form.current?.getValues()
      if (values && values.file && values.mime) {
        try {
          return handleUploadSingleFileToWasabi(
            values.file,
            link,
            values.mime,
            {
              'Content-Type': values.mime,
              'x-amz-meta-imagewidth': values.width.toString(),
              'x-amz-meta-imageheight': values.height.toString(),
            },
          )
        } catch (error) {
          return false
        }
      }
      return false
    },
    [form],
  )

  const uploadFileMetadata = useCallback(
    async (link: string) => {
      const values = form.current?.getValues()
      if (values && values.file && values.extension) {
        const response = await requestData('forkygram_post_posts', {
          useDefaultMessage: true,
          actionType: 'execute',
          data: {
            caption: values.caption,
            duration: values.duration,
            tag_id: values.tag.id,
            height: values.height,
            width: values.width,
            size: values.size,
            exif: values.exif,
            link,
          },
        })
        if (typeof response !== 'string' && response.status === 201) {
          const {result} = response.data
          if (result.tree?.footprint_count) {
            update('lastTreeState', {
              footprintCount: result.tree.footprint_count,
            })
          }
          showSnackbar(
            translate('forkygram:uploadSuccessMessage', {
              context: data.treeId && 'leaf',
            }),
          )
          return result
        }
        if (typeof response !== 'string' && response.status === 429) {
          const {detail} = response.data
          if (detail?.eligible_at) {
            const now = new Date()
            const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes()

            const eligibleDate = new Date(detail.eligible_at)
            const eligibleTimeInMinutes =
              eligibleDate.getHours() * 60 + eligibleDate.getMinutes()

            const remainingMinutes =
              eligibleTimeInMinutes - currentTimeInMinutes
            const remainingTime = Math.floor(remainingMinutes / 60)
            const remainingTimeMinutes = remainingMinutes % 60

            const timeValue =
              remainingTime > 0 ? remainingTime : remainingTimeMinutes
            const timeUnit = translate(
              remainingTime > 0 ? 'global:hour' : 'global:minute',
            ).toLowerCase()

            showSnackbar(
              translate('forkygram:uploadLimitMessage', {
                context: data.treeId && 'leaf',
                value: timeValue,
                unit: timeUnit,
              }),
            )
            return detail
          }
        }
        return false
      }
      return false
    },
    [data.treeId, form, translate, update],
  )

  const uploadContent = useCallback(async () => {
    try {
      const link = await getContentLink()
      if (!link) return

      const isPublished = await publishToWasabi(link)
      const isMetadataUploaded = await uploadFileMetadata(link)

      if (isPublished && isMetadataUploaded) {
        setSrc(undefined)
        setTimeout(() => {
          data.treeId ? handleNavigateFotoTree() : handleNavigateProfile()
        }, 1000)
      }
    } finally {
      setLoading(false)
    }
  }, [
    data.treeId,
    getContentLink,
    handleNavigateFotoTree,
    handleNavigateProfile,
    publishToWasabi,
    setLoading,
    setSrc,
    uploadFileMetadata,
  ])

  const handleLoadForm = useCallback(() => {
    if (data.file) {
      const img = data.file
      const filename = data.file.name
      const extension = filename.substring(filename.lastIndexOf('.') + 1) as
        | ImageExtensionType
        | VideoExtensionType
      const mime = img.type
      const {type, size} = img

      if (!FORKYGRAM_UPLOAD_TYPE.includes(type)) {
        handleRedirect()
        showSnackbar(
          img.type.startsWith('image')
            ? translate('forkygram:uploadInvalidImageFormat')
            : translate('forkygram:uploadInvalidVideoFormat'),
        )
        return
      }
      if (type.startsWith('image') && size > FORKYGRAM_UPLOAD_MAX_IMAGE_SIZE) {
        handleRedirect()
        showSnackbar(translate('forkygram:uploadMaxImageSizeMessage'))
        return
      }

      if (type.startsWith('video') && size > FORKYGRAM_UPLOAD_MAX_VIDEO_SIZE) {
        handleRedirect()
        showSnackbar(translate('forkygram:uploadMaxVideoSizeMessage'))
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        const url = reader.result?.toString()

        if (url?.includes('image')) {
          const image = document.createElement('img')
          image.src = url
          image.onload = () => {
            const {width, height} = image

            if (width * height > FORKYGRAM_UPLOAD_MAX_RESOLUTION) {
              setSrc(undefined)
              handleRedirect()
              showSnackbar(
                translate('forkygram:uploadMaxImageResolutionMessage'),
              )
              return
            }
            if (
              width < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION ||
              height < FORKYGRAM_UPLOAD_MIN_IMAGE_RESOLUTION
            ) {
              setSrc(undefined)
              handleRedirect()
              showSnackbar(
                translate('forkygram:uploadMinImageResolutionMessage'),
              )
              return
            }
            if (form.current) {
              form.current.setValue('extension', extension)
              form.current.setValue('height', height)
              form.current.setValue('width', width)
              form.current.setValue('size', size)
              form.current.setValue('mime', mime)
              form.current.setValue('file', img)
            }
            setSrc(url)
          }
        }
        if (url?.includes('video')) {
          const vid = document.createElement('video')
          vid.src = url

          vid.onloadedmetadata = () => {
            const {videoWidth, videoHeight, duration} = vid

            if (duration > FORKYGRAM_UPLOAD_MAX_VIDEO_DURATION) {
              setSrc(undefined)
              handleRedirect()
              showSnackbar(translate('forkygram:uploadMaxVideoDurationMessage'))
              return
            }
            if (videoWidth > FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH) {
              setSrc(undefined)
              handleRedirect()
              showSnackbar(translate('forkygram:uploadMaxVideoWidthMessage'))
              return
            }
            if (form.current) {
              const vidDuration = duration * 1000
              const finalDuration = Math.floor(vidDuration)

              form.current.setValue('extension', extension)
              form.current.setValue('height', videoHeight)
              form.current.setValue('width', videoWidth)
              form.current.setValue('size', size)
              form.current.setValue('mime', mime)
              form.current.setValue('file', img)
              form.current.setValue('duration', finalDuration)
            }

            setSrc(url)
          }
        }
      }
      reader.readAsDataURL(img)
    }
  }, [data.file, form, handleRedirect, setSrc, translate])

  useDidMount(handleLoadForm)

  return useMemo(
    () => ({
      publish: uploadContent,
      navigateFototreeDetail: handleNavigateFotoTree,
    }),
    [handleNavigateFotoTree, uploadContent],
  )
}
