import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {ForkygramProfileTabType} from 'types'
import {getAppStoreLink, getPlayStoreLink} from 'utils'
import {useWindowMode} from 'windows'
import {Image, Paragraph, TabView} from 'common/components'
import {useDispatch} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateProfileTabProps} from './TemplateProfileTabProps'
import TemplateProfileTabPost from './TemplateProfileTabPost'
import TemplateProfileTabCreation from './TemplateProfileTabCreation'

const StyledNotCreatorContainer = styled.div`
  display: flex;
  flex: 1;
  height: inherit;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: ${convertUnit(50)};
`

const StyledMiddleParagraph = styled.div`
  margin-top: ${convertUnit(10)};
  margin-bottom: ${convertUnit(15)};
`

const StyledStoreImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${convertUnit(20)};
`

const StyledImg = styled(Image)`
  height: ${convertUnit(35)};
  width: ${convertUnit(100)};
  object-fit: contain;
  margin-right: ${convertUnit(25)};
`

const StyledLink = styled.a`
  text-decoration: none;
`

export default function TemplateProfileTab({
  self,
  memberId,
  isLive,
  kycStatus,
  stateContentType,
  stateSelectTab,
  stateCreationData,
  stateSelect,
  stateSelectMode,
  stateCreationType,
  stateGroupData,
  stateSelectGroup,
  stateResendButtonType,
  showOnlyTabContents,
}: TemplateProfileTabProps) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const selectMode = stateSelectMode[0]
  const mode = useWindowMode()
  const googlePlayImg = IMAGE_ASSET('giftshop', 'google-play-icon.png')
  const appStoreImg = IMAGE_ASSET('giftshop', 'app-store-icon.png')

  const handleRenderNotCreator = useMemo(
    () => (
      <StyledNotCreatorContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('giftShop:myCreationDownloadTitle')}
        </Paragraph>
        <StyledMiddleParagraph>
          <Paragraph fontSize="m">
            {translate('giftShop:myCreationDownloadDesc')}
          </Paragraph>
        </StyledMiddleParagraph>
        <Paragraph
          fontSize="m"
          fontWeight={mode === 'mobile' ? 'medium' : 'bold'}
          color="gray_5">
          {translate('giftShop:myCreationDownloadActivateDesc')}
        </Paragraph>
        <StyledStoreImagesContainer>
          <StyledLink href={getPlayStoreLink()} target="_blank">
            <StyledImg src={googlePlayImg} alt="google-play" />
          </StyledLink>
          <StyledLink href={getAppStoreLink()} target="_blank">
            <StyledImg src={appStoreImg} alt="app-store" />
          </StyledLink>
        </StyledStoreImagesContainer>
      </StyledNotCreatorContainer>
    ),
    [appStoreImg, googlePlayImg, mode, translate],
  )

  const handleRenderTabTitle = useCallback(
    (key: ForkygramProfileTabType) => {
      switch (key) {
        case 'post':
          return translate('forkygram:post')
        case 'creation':
          return translate('giftShop:sell')
      }
    },
    [translate],
  )

  const handleRenderTabItem = useCallback(
    (key: ForkygramProfileTabType) => {
      switch (key) {
        case 'post':
          return (
            <TemplateProfileTabPost
              self={self}
              memberId={memberId}
              kycStatus={kycStatus}
              isLive={isLive}
            />
          )

        case 'creation':
          return kycStatus === 'verified' || kycStatus === 'verified_check' ? (
            <TemplateProfileTabCreation
              stateContentType={stateContentType}
              stateCreationData={stateCreationData}
              stateSelect={stateSelect}
              stateSelectMode={stateSelectMode}
              stateCreationType={stateCreationType}
              stateGroupData={stateGroupData}
              stateSelectGroup={stateSelectGroup}
              stateResendButtonType={stateResendButtonType}
              showOnlyTabContents={showOnlyTabContents}
            />
          ) : (
            handleRenderNotCreator
          )
      }
    },
    [
      handleRenderNotCreator,
      isLive,
      kycStatus,
      memberId,
      self,
      showOnlyTabContents,
      stateContentType,
      stateCreationData,
      stateCreationType,
      stateGroupData,
      stateResendButtonType,
      stateSelect,
      stateSelectGroup,
      stateSelectMode,
    ],
  )

  return (
    <TabView
      routes={['creation', 'post']}
      renderTitle={handleRenderTabTitle}
      renderItem={handleRenderTabItem}
      showTab={!showOnlyTabContents}
      disabledColor="gray_4"
      bottomBorder={false}
      stateSelect={stateSelectTab}
      onClickItem={(route) => {
        update('giftshopState', {
          lastViewedProfileTab: route,
        })
      }}
      disabledClick={selectMode}
      containerStyle={{
        height: 'inherit',
        position: 'sticky',
        top: 0,
        overflow: 'hidden',
      }}
    />
  )
}
