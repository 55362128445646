import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  ForkygramUploadData,
  ForkygramUploadTagErrorType,
  TreeFotoTreeLeafResponse,
} from 'types'
import {
  getThemeColor,
  showSnackbar,
  useDidMount,
  useHistory,
  useLocation,
  useQueryParamValue,
} from 'utils'
import {
  ButtonHeader,
  Icon,
  ListLazy,
  ModalLoading,
  Paragraph,
} from 'common/components'
import {
  FORKYGRAM_FEED_QUERY_LIMIT,
  GIFT_SHOP_HEADER_HEIGHT,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import convertUnit from 'lib/unit'
import animationBackgroundData from 'assets/lottie/tree_background.json'
import {ForkygramTemplateUploadForm} from 'pages/forkygram'
import {TemplateProfileDetailContentSkeleton} from '../../../template'
import {FototreeMemoryContent} from '../containers'

interface StyledRootContainerProps {
  isEdit: boolean
}

const StyledRootContainer = styled.div<StyledRootContainerProps>`
  ${({isEdit, theme}) => ({
    backgroundColor: isEdit ? theme.white_1 : theme.black,
  })}
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const StyledIconContainer = styled.div`
  position: absolute;
  align-self: center;
  top: ${convertUnit(20)};
  z-index: 2;
  width: 100%;
  max-width: ${convertUnit(600)};
  cursor: pointer;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    left: ${convertUnit(20)};
  }
`

const StyledRoundedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.4)};
  width: ${convertUnit(42)};
  height: ${convertUnit(42)};
  border-radius: ${convertUnit(28)};
  cursor: pointer;
`

const StyledLottieBackgound = styled(Lottie)`
  position: fixed;
  height: 100%;
  width: 100%;
  margin-left: ${convertUnit(-20)};
  margin-top: ${convertUnit(180)};
  background: radial-gradient(40% 75% at 50% 30%, #3c5366 0%, #06080b 55%);
  transform: scale(1.8);

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(50)};
    transform: scale(2.6);
  }

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-top: 0;
    transform: scale(4);
  }
`

const StyledSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  width: 100%;
  border-color: ${({theme}) => theme.gray_1};
  border-bottom-style: solid;
  border-bottom-width: ${convertUnit(1)};
`

export default function TreeFototreeFeedScreen() {
  const history = useHistory()
  const {translate} = useTranslation()
  const {alias}: {alias: string} = useParams()
  const {id} = useQueryParamValue(['id'])
  const {
    data: datas,
    dataTag,
    selectedItemId,
    memberId,
    initialPage,
    isPastMemory,
  } = useLocation('tree_fototree_feed').state
  const stateData = useState<readonly TreeFotoTreeLeafResponse[]>(datas || [])
  const shareData = useRef<TreeFotoTreeLeafResponse>()
  const formRef = useRef<UseFormMethods<ForkygramUploadData>>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = stateData
  const statePause = useState(false)
  const stateMute = useState(true)
  const stateEdit = useState<TreeFotoTreeLeafResponse>()
  const [edit, setEdit] = stateEdit
  const [scrollPos, setScrollPos] = useState(0)
  const [treeId, setTreeId] = useState(dataTag?.tree_id || '')
  const stateFormError = useState<ForkygramUploadTagErrorType>('none')
  const formError = stateFormError[0]

  const handleChackTreeExist = useCallback(() => {
    if (alias !== dataTag?.alias) {
      requestData('tree_get_fototree_check', {
        params: {alias},
        onRequestSuccess: ({status, data: {result: detail}}) => {
          if (status === 200) {
            setTreeId(detail.id)
          }
        },
      })
    }
  }, [alias, dataTag?.alias])

  const handleLoadShareData = useCallback(async () => {
    if (id) {
      await requestData('tree_get_leaf_share', {
        params: {id},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) shareData.current = result
        },
      })
    }
  }, [id])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      if (page === 1) await handleLoadShareData()
      const response = await requestData('tree_get_leaves', {
        actionType: 'fetch',
        useDefaultMessage: true,
        params: {
          page,
          limit,
          tree_id: treeId,
          member_id: memberId,
          is_past_memory: isPastMemory,
        },
      })
      if (typeof response !== 'string' && response.status === 200) {
        if (shareData.current) {
          return [shareData.current, ...response.data.result]
        }
        return response.data.result
      }
      return []
    },
    [handleLoadShareData, isPastMemory, memberId, shareData, treeId],
  )

  const handleFollow = useCallback(
    (item: TreeFotoTreeLeafResponse) => {
      setData((prev) =>
        prev.map((x) =>
          x.member.id === item.member.id
            ? {...x, member: {...x.member, is_followed: true}}
            : x,
        ),
      )
    },
    [setData],
  )

  const handlePatchPost = useCallback(() => {
    if (edit && formRef.current) {
      const {caption, tag} = formRef.current.getValues()
      setLoading(true)
      requestData('forkygram_patch_post', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {id: edit.post_id, caption, tag_id: tag.id},
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('forkygram:postUpdated'))
            setData((prev) =>
              prev.map((item) =>
                item.post_id === edit.post_id ? {...item, caption} : item,
              ),
            )
            if (tag.id !== dataTag?.id) {
              setData((prev) =>
                prev.filter((item) => item.post_id !== edit.post_id),
              )
              data.length <= 1 && history.goBack()
            }
            setEdit(undefined)
          }
        },
      })
    }
  }, [data.length, dataTag?.id, edit, history, setData, setEdit, translate])

  const selectedComponentRef = useCallback((node: HTMLDivElement) => {
    node &&
      node.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      })
  }, [])

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafResponse, index: number) => (
      <FototreeMemoryContent
        componentRef={
          selectedItemId && selectedItemId === item.post_id
            ? selectedComponentRef
            : null
        }
        data={item}
        dataTag={dataTag}
        index={index}
        statePause={statePause}
        stateMute={stateMute}
        scrollPosition={scrollPos}
        onReported={(reportedId) => {
          setData((prev) => prev.filter((post) => post.post_id !== reportedId))
        }}
        onEdited={() => setEdit(item)}
        onFollow={() => handleFollow(item)}
      />
    ),
    [
      dataTag,
      handleFollow,
      scrollPos,
      selectedComponentRef,
      selectedItemId,
      setData,
      setEdit,
      stateMute,
      statePause,
    ],
  )

  const handleRenderList = useMemo(
    () =>
      treeId && (
        <ListLazy
          loadData={handleLoadData}
          stateData={stateData}
          renderItem={handleRenderItem}
          listInitializeElement={<TemplateProfileDetailContentSkeleton />}
          keyExtractor={(item) => item.post_id}
          scrollbar={false}
          initialPage={initialPage}
          limitPerPage={FORKYGRAM_FEED_QUERY_LIMIT}
          onScrollList={(e) => setScrollPos(e.currentTarget.scrollTop)}
          scrollSnapType="y mandatory"
        />
      ),
    [handleLoadData, handleRenderItem, initialPage, stateData, treeId],
  )

  const handleRenderEditContent = useMemo(
    () => (
      <ForkygramTemplateUploadForm
        stateFormError={stateFormError}
        editContent
        thumbnail={edit?.thumbnail}
        caption={edit?.caption}
        tag={dataTag}
        contentType={edit?.content_type}
        src={edit?.url}
        onFormUpdate={(item) => {
          formRef.current = item
        }}
      />
    ),
    [
      dataTag,
      edit?.caption,
      edit?.content_type,
      edit?.thumbnail,
      edit?.url,
      stateFormError,
    ],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledIconContainer>
        <StyledRoundedIcon>
          <Icon
            type="back"
            size={convertUnit(20)}
            color="white_1"
            onClick={history.goBack}
          />
        </StyledRoundedIcon>
      </StyledIconContainer>
    ),
    [history.goBack],
  )

  const handleRenderEditHeader = useMemo(
    () => (
      <StyledSectionHeader>
        <ButtonHeader
          icon="back"
          color="primary_5"
          size={20}
          label={translate('global:back')}
          onClick={() => setEdit(undefined)}
        />
        <Paragraph fontSize="l" fontWeight="medium">
          {translate('forkygram:uploadHeader')}
        </Paragraph>
        <ButtonHeader
          icon="check"
          color={formError === 'none' ? 'primary_5' : 'gray_3'}
          size={20}
          style={{marginLeft: 'auto'}}
          label={translate('global:save')}
          onClick={formError === 'none' ? handlePatchPost : undefined}
        />
      </StyledSectionHeader>
    ),
    [formError, handlePatchPost, setEdit, translate],
  )

  useDidMount(handleChackTreeExist)

  return (
    <StyledRootContainer isEdit={!!edit}>
      <ModalLoading visible={loading} />
      {edit ? (
        <>
          {handleRenderEditHeader}
          {handleRenderEditContent}
        </>
      ) : (
        <>
          <StyledLottieBackgound animationData={animationBackgroundData} />
          {handleRenderHeader}
          {handleRenderList}
        </>
      )}
    </StyledRootContainer>
  )
}
