import {AnyLayout, Expression} from 'mapbox-gl'
import {TreeRegisterCountry} from 'types'

export const HOST_REGISTRATION_MAX_DOCUMENT_SIZE = 5242880
export const HOST_REGISTRATION_DOCUMENT_TYPE = [
  'application/pdf',
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
export const HOST_LOGO_MAX_SIZE = 52428800
export const HOST_IMAGE_TYPE = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heif',
  'image/heic',
]
export const HOST_MIN_PRICE = 0
export const HOST_MAX_PRICE = 1000000
export const HOST_COMPANY_MAX_LENGTH = 60
export const HOST_COMPANY_MIN_LENGTH = 4
export const HOST_BANK_NUMBER_MAX_LENGTH = 16
export const HOST_BANK_NUMBER_MIN_LENGTH = 10
export const HOST_ACCOUNT_HOLDER_MAX_LENGTH = 150
export const HOST_HASHTAG_MAX_LENGTH = 30
export const HOST_HASHTAG_MIN_LENGTH = 4
export const HOST_DEFAULT_FEE = 20
export const HOST_DEFAULT_FEE_INDEPENDENT = 10
export const HOST_DEFAULT_FEE_INTERNAL = 10
export const HOST_MAX_FEE = 100
export const HOST_MAX_FEE_INDEPENDENT = 30
export const HOST_MAX_FEE_INTERNAL = 90
export const HOST_BIO_MAX_LENGTH = 80
export const HOST_MAX_WITHDRAW_AMOUNT = 100000000
export const HOST_NAME_MAX_LENGTH = 60
export const HOST_NAME_MIN_LENGTH = 4

export const TREE_UPLOAD_PROFILE_MAX_SIZE = 15707136
export const TREE_MAX_BIO_CHARACTER = 300
export const TREE_MAX_CITY_NAME_CHARACTER = 70
export const TREE_MAX_FARMER = 5
export const TREE_MAX_NAME_ALIASES = 3
export const TREE_MAX_NAME_CHARACTER = 50
export const TREE_MIN_NAME_CHARACTER = 4
export const TREE_MAX_STORY_CHARACTER = 300
export const TREE_MAX_VIDEO_INTRO_DURATION = 15
export const TREE_MIN_VIDEO_INTRO_DURATION = 3
export const TREE_MAX_EVENT_DATE = 7

export const HOST_REGISTER_COUNTRY: TreeRegisterCountry = {
  id: '972blZMQPLq4mnkdRrGjrzK5Nx3BA10v',
  name: 'Indonesia',
}

export const TREE_MAP_DEFAULT_ZOOM = 10
export const TREE_MAP_FOCUS_ZOOM = 15
export const TREE_MAP_MIN_ZOOM = 3
export const TREE_MAP_MAX_ZOOM = 17
export const TREE_MAP_CREATOR_ZOOM = 15

export const TREE_MAP_LAYER_ID_ACTIVE = 'map-active-layer'
export const TREE_MAP_LAYER_ID_FOTOTREE = 'map-fototree-layer'
export const TREE_MAP_LAYER_ID_CREATOR_LIVE = 'map-creator-layer'

export const TREE_MAP_LAYER_LAYOUT: AnyLayout = {
  'icon-anchor': 'center',
  'text-font': ['Ubuntu Regular'],
  'text-justify': 'auto',
  'text-optional': true,
  'text-radial-offset': 1.25,
  'text-variable-anchor': ['left', 'bottom', 'right', 'top'],
  'text-size': ['step', ['zoom'], 10, 9, 12],
  'icon-allow-overlap': ['step', ['zoom'], false, 9, true],
  'icon-ignore-placement': ['step', ['zoom'], false, 9, true],
  'icon-size': 0.2,
}
export const TREE_MAP_LAYER_LAYOUT_FOTOTREE: AnyLayout = {
  'symbol-sort-key': ['-', ['get', 'leaf_count']],
  'text-field': ['step', ['zoom'], '', 8, ['get', 'name']],
  'icon-image': [
    'match',
    ['get', 'category'],
    'community',
    'map-pin-community',
    'event',
    'map-pin-event',
    'map-pin-place',
  ],
}
export const TREE_MAP_LAYER_LAYOUT_CREATOR_LIVE: AnyLayout = {
  'icon-image': 'map-pin-creator',
  'symbol-sort-key': ['-', ['get', 'created_at']],
  'text-allow-overlap': true,
  'text-field': ['step', ['zoom'], '', 8, ['get', 'member_username']],
}

export const TREE_MAP_LAYER_FILTER_GENERATED_FOTOTREE: Expression = [
  'any',
  ['==', ['get', 'is_generated'], false],
  [
    'all',
    ['==', ['get', 'is_generated'], true],
    ['any', ['>=', ['get', 'leaf_count'], 1], ['>=', ['zoom'], 9]],
  ],
]

export const TREE_UPLOAD_VIDEO_TYPE: string[] = [
  'video/mov',
  'video/mp4',
  'video/quicktime',
]

export const TREE_CHAT_MAX_LENGTH = 300
export const TREE_CHAT_SCROLL_THRESHOLD = 50

export const TREE_HEADER_HEIGHT = 84
export const TREE_LEADERBOARD_LOAD_LIMIT = 10
export const TREE_GLOBAL_CHAT_REPLY_LIMIT = 10

export const TREE_THUMBNAIL_ICON_SIZE_RATIO = 1 / 2
export const TREE_THUMBNAIL_RETRY_MAX = 5
export const TREE_THUMBNAIL_RETRY_TIMEOUT = 30_000 // 30s
export const TREE_THUMBNAIL_RETRY_THRESHOLD = 600_000 // 10m
