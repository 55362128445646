import React, {useState} from 'react'
import {ExplorePaginationProviderProps} from './ExplorePaginationProviderProps'
import {ExplorePaginationContext} from './ExplorePaginationContext'

export default function ExplorePaginationProvider({
  children,
}: ExplorePaginationProviderProps) {
  const stateExplorePagination = useState(1)

  return (
    <ExplorePaginationContext.Provider value={stateExplorePagination}>
      {children}
    </ExplorePaginationContext.Provider>
  )
}
