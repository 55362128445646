import React, {useCallback, useMemo, useState} from 'react'
import {sha256} from 'js-sha256'
import {useScript, appleAuthHelpers} from 'react-apple-signin-auth'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {translate} from 'i18n'
import {AuthRegisterWaitingModal} from 'pages'
import {requestData} from 'services'
import {
  getAppleSSOClientId,
  getAppleSSORedirectUri,
  showSnackbar,
  useHistory,
} from 'utils'
import {
  AuthFormSimpleRegisterData,
  AuthLoginResponse,
  AuthSSOAppleResponse,
} from 'types'
import {Button, Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {reduxUpdateDispatcher, useSelector} from 'lib/redux'
import {TemplateAuthAccessUsernameModal} from '../../../../template'
import {AuthLoginAppleProps} from './AuthLoginAppleProps'

const StyledButtonLoginApple = styled(Button)`
  ${({theme}) => ({borderColor: theme.white_3})}
  border-style: solid;
  border-width: ${convertUnit(1)};
  display: flex;
  flex: 1;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-left: 0;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: ${convertUnit(15)};
`

export default function AuthLoginApple({
  label,
  buttonStyle,
}: AuthLoginAppleProps) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const {tag_id, tree_id} = useSelector('treeQrState') || {}
  const form = useForm<AuthFormSimpleRegisterData>({
    defaultValues: {
      username: '',
    },
  })
  const {setError} = form
  const stateModalUsername = useState(false)
  const stateModalUsernameLoading = useState(false)
  const [ssoToken, setSsoToken] = useState('')

  const handleRenderWaitingModal = useMemo(
    () => (
      <AuthRegisterWaitingModal
        visible={modal}
        toggleModal={() => setModal(false)}
      />
    ),
    [modal],
  )

  const handleFailed = useCallback(() => {
    setLoading(false)
    showSnackbar(translate('giftShop:snackbarFailedExecute'))
  }, [])

  const handleEasySignupLogin = useCallback(
    (tokenId: string, username?: string) => {
      if (username) {
        stateModalUsernameLoading[1](true)
      }
      requestData('auth_easy_signup_login_sso_apple', {
        data: {token: tokenId, username},
        onRequestReceived: () => {
          stateModalUsernameLoading[1](false)
        },
        onRequestSuccess: ({data, status}) => {
          if (status === 200) {
            const userInfo = data.result as AuthLoginResponse
            reduxUpdateDispatcher('user', userInfo)
            if (tree_id && tag_id) {
              history.pushQuery({
                path: 'tree_events_bib_masonry',
                state: {
                  tree_url: '',
                  tree_name: '',
                  fromScan: true,
                },
                queryObject: {
                  tree_id,
                  tag_id,
                  search: '',
                },
              })
              return
            }
            const {search} = history.location
            if (search.startsWith('?next')) {
              if (history.length > 1) {
                history.goBack()
              } else {
                window.location.replace(decodeURIComponent(search))
              }
              return
            }
            location.reload()
          } else if (status === 202) {
            if (Array.isArray(data.result)) {
              history.push('auth_easy_signup_sso', {
                token: tokenId,
                ssoMethod: 'apple',
                suggestions: data.result,
              })
            } else {
              history.push('auth_easy_signup_underage', {
                child_consent_code: data.result.child_consent_code,
                token: tokenId,
                method: 'apple',
              })
            }
          } else if (status === 400) {
            if (data.detail && data.detail.username === 'invalid') {
              setError('username', {
                type: 'unavailable',
                message: translate('auth:registerEasySignupUsernameNotMatch'),
              })
            }
          } else if (status === 401) {
            stateModalUsername[1](true)
          } else if (status === 503) {
            setModal(true)
          }
        },
        onRequestFailed: handleFailed,
      })
    },
    [
      handleFailed,
      history,
      setError,
      stateModalUsername,
      stateModalUsernameLoading,
      tag_id,
      tree_id,
    ],
  )

  const handleSuccess = useCallback(
    (response: AuthSSOAppleResponse) => {
      const {id_token} = response.authorization
      setSsoToken(id_token)
      handleEasySignupLogin(id_token)
    },
    [handleEasySignupLogin],
  )

  const handleClick = useCallback(async () => {
    setLoading(true)
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: getAppleSSOClientId(),
        redirectURI: getAppleSSORedirectUri(),
        responseType: 'ALL',
        scope: 'email name',
        nonce: sha256('nonce'),
        state: 'state',
        usePopup: true,
      },
      onError: handleFailed,
      onSuccess: (res) => handleSuccess(res),
    })
  }, [handleFailed, handleSuccess])

  const handleRenderAppleButton = useMemo(
    () => (
      <StyledButtonLoginApple
        onClick={handleClick}
        label={label}
        leftIcon={<StyledIcon type="apple" />}
        backgroundColor="white_3"
        backgroundHoverColor="white_3"
        color="black"
        isLoading={loading}
        disabled={loading}
        style={buttonStyle}
      />
    ),
    [buttonStyle, handleClick, label, loading],
  )

  const handleRenderModalUsername = useMemo(
    () => (
      <TemplateAuthAccessUsernameModal
        stateVisible={stateModalUsername}
        stateLoading={stateModalUsernameLoading}
        form={form}
        onClick={(username) => handleEasySignupLogin(ssoToken, username)}
      />
    ),
    [
      form,
      handleEasySignupLogin,
      ssoToken,
      stateModalUsername,
      stateModalUsernameLoading,
    ],
  )

  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC)

  return (
    <>
      {handleRenderWaitingModal}
      {handleRenderModalUsername}
      {handleRenderAppleButton}
    </>
  )
}
