import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT,
  SERVICE_CANCELLATION_GET_CREATION_PAGINATION,
  SERVICE_CANCELLED_RESPONSE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftShopTemplateMasonry} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData, GiftShopCreationDateGroupData} from 'types'
import {
  clearUserCache,
  getBoxShadow,
  parseDate,
  showModalLimitedAccess,
  useDidMount,
  useFirestorePaymentResendRefresh,
  useHistory,
  useRefreshResend,
} from 'utils'
import {Button, Icon} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateProfileEmptyTab} from '../EmptyTab'
import {TemplateProfileTabCreationProps} from './TemplateProfileTabItemProps'
import {TemplateTabListHeaderCreation} from '../TabListHeader'
import {TemplateProfileCreationGroup} from '../CreationGroup'
import {TemplateSorryModalContent} from '../../../sorry-modal'

const StyledListContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledStickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  height: ${convertUnit(50)};
`

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledFloatingBtnContainer = styled.div`
  position: fixed;
  bottom: ${convertUnit(20)};
  right: ${convertUnit(20)};
  z-index: 5;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    bottom: ${convertUnit(20 + GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT)};
  }
`

const StyledIconContainer = styled.div`
  margin-right: ${convertUnit(12)};
`

const StyledButton = styled(Button)`
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {
      horizontalOffset: 0,
      verticalOffset: 4,
      blurRadius: 16,
      opacity: 0.16,
      color: 'black',
    })};
`

export default function TemplateProfileTabCreation({
  stateCreationData,
  stateContentType,
  stateSelect,
  stateSelectMode,
  stateCreationType,
  stateGroupData,
  stateSelectGroup,
  stateResendButtonType,
  showOnlyTabContents,
}: TemplateProfileTabCreationProps) {
  const data = stateCreationData[0]
  const {translate} = useTranslation()
  const history = useHistory()
  const [select, setSelect] = stateSelect
  const [selectMode, setSelectMode] = stateSelectMode
  const {resendSortType} = useSelector('giftshopState')
  const creationType = stateCreationType[0]
  const [resendButtonType, setResendButtonType] = stateResendButtonType
  const [sorryModal, setSorryModal] = useState(false)
  const {resendCount, resendpologyCount} = useSelector('giftshopState')
  const {id, photo, username} = useSelector('user') || {}
  const {creatorStatus, kycStatus} = useSelector('yuserActivationState')
  const {status: childStatus} = useSelector('parentalConsentState')
  const setSelectGroup = stateSelectGroup[1]
  const contentType = stateContentType[0]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshDate = useMemo(() => new Date(), [contentType, resendSortType])
  const {update} = useDispatch()
  const masonrySortType = useMemo(() => {
    if (creationType === 'sold') {
      return 'purchased_at'
    }
    if (creationType === 'resend') {
      return resendSortType
    }
    return 'created_at'
  }, [creationType, resendSortType])

  const lastUpdate = useFirestorePaymentResendRefresh()
  const {handleRenderButton} = useRefreshResend({
    lastUpdate,
    onRefresh: () => {
      history.push(
        'giftshop_profile',
        {
          creationType: 'resend',
          self: true,
        },
        username,
      )
      window.location.reload()
    },
  })

  const handleLoadMasonry = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('giftshop_get_creation', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {
          page,
          limit,
          filter: creationType,
          sort_by: masonrySortType,
          sort_dir:
            resendSortType && resendSortType === 'purchased_at'
              ? 'asc'
              : 'desc',
          content_type: 'all',
        },
        cancelId: SERVICE_CANCELLATION_GET_CREATION_PAGINATION,
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      if (typeof response !== 'string' && response.status === 200) {
        const {data: creationData} = response.data.result
        return creationData
      }
      return []
    },
    [creationType, masonrySortType, resendSortType],
  )

  const handleSelectRule = useCallback(
    (item: GiftShopContentData) => {
      const {content_type, purchase_status, is_deletable} = item
      if (
        (creationType === 'resend' || creationType === 'resendpology') &&
        content_type !== 'video' &&
        purchase_status !== 'resendpology' &&
        !is_deletable
      )
        return true
      return false
    },
    [creationType],
  )

  const handleLoadGroup = useCallback(
    async (_page: number, limit: number, _search: string, anchor: string) => {
      const response = await requestData('giftshop_get_creation_group', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {
          limit,
          filter: creationType,
          sort_by: 'created_at',
          sort_dir: 'desc',
          content_type: contentType,
          latest_date: anchor ? parseDate(anchor, 'YYYY-MM-DD') : undefined,
        },
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.group_data
        : []
    },
    [contentType, creationType],
  )

  const handleClickMasonry = useCallback(
    (item: GiftShopContentData) => {
      selectMode
        ? setSelect((previous) =>
            previous.includes(item)
              ? previous.filter((value) => value.content_id !== item.content_id)
              : [...previous, item],
          )
        : history.push(
            'giftshop_profile_creation_detail',
            {
              data: data.map((datum) => ({
                ...datum,
                creator_id: id,
                creator_name: username,
                profile_url: photo,
              })),
              selectedItemId: item.content_id,
              creationType,
            },
            username,
          )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creationType, data, history, selectMode, setSelect],
  )

  const handleGetResendCount = useCallback(() => {
    requestData('giftshop_get_creation_count', {
      actionType: 'fetch',
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          update('giftshopState', {
            resendCount: result.total_resend,
            resendpologyCount: result.total_resendpology,
          })
          result.total_resend === 0 && clearUserCache('creation')
        }
      },
    })
  }, [update])

  const handleClickDateGroup = useCallback(
    (item: GiftShopCreationDateGroupData) => {
      selectMode
        ? setSelectGroup((prev) =>
            prev.includes(item)
              ? prev.filter((val) => val.date !== item.date)
              : [...prev, item],
          )
        : history.pushQuery({
            path: 'giftshop_profile_creation_group',
            state: {
              date: item.date.substring(0, 10),
              folderPhotoCount: item.count,
            },
            queryObject: {
              date: item.date.substring(0, 10),
              type: creationType,
              page: 1,
            },
            recursivePath: username,
          })
    },
    [creationType, history, selectMode, setSelectGroup, username],
  )

  const handleClickUpload = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'verified' || kycStatus === 'verified_check') {
      history.push('giftshop_upload', {creatorStatus, kycStatus})
    } else setSorryModal((prev) => !prev)
  }, [childStatus, creatorStatus, history, kycStatus])

  const handleRenderUploadKYCModal = useMemo(
    () => (
      <TemplateSorryModalContent
        visible={sorryModal}
        upperDesc={translate('giftShop:roboyuUploadNotActivatedDesc')}
        bottomDesc={translate('giftShop:roboyuUploadNotActivatedInfo')}
        toggleModal={() => setSorryModal((prev) => !prev)}
      />
    ),
    [sorryModal, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <TemplateProfileEmptyTab
        description={translate('giftShop:creationFilterEmpty')}
      />
    ),
    [translate],
  )

  const handleRenderEmptyTabAll = useMemo(
    () => (
      <TemplateProfileEmptyTab
        description={translate('giftShop:creationFilterEmptyAll')}
        buttonLabel={translate('giftShop:creationFilterEmptyAllButton')}
        onClickButton={handleClickUpload}
      />
    ),
    [handleClickUpload, translate],
  )

  const handleRenderHeader = useMemo(
    () =>
      !showOnlyTabContents && (
        <StyledStickyHeader>
          <TemplateTabListHeaderCreation
            stateCreationType={stateCreationType}
            resendCount={resendCount}
            resendpologyCount={resendpologyCount}
            disabled={selectMode}
          />
        </StyledStickyHeader>
      ),
    [
      resendCount,
      resendpologyCount,
      selectMode,
      showOnlyTabContents,
      stateCreationType,
    ],
  )

  const handleRenderListEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        {creationType === 'all' ? handleRenderEmptyTabAll : handleRenderEmpty}
      </StyledEmptyContainer>
    ),
    [creationType, handleRenderEmpty, handleRenderEmptyTabAll],
  )

  const handleRenderMasonry = useMemo(
    () =>
      (creationType === 'resend' ||
        creationType === 'resendpology' ||
        creationType === 'sold') && (
        <GiftShopTemplateMasonry
          refresh={refreshDate}
          showPurchaseStatusBadge={!selectMode}
          showUnwishlistBadge
          cache="creation"
          loadData={handleLoadMasonry}
          listEmptyElement={handleRenderListEmpty}
          search={creationType}
          onClickItem={handleClickMasonry}
          scrollbar={false}
          toggleSelect={selectMode}
          selected={select}
          stateData={stateCreationData}
          onSelectRule={handleSelectRule}
        />
      ),
    [
      creationType,
      handleClickMasonry,
      handleLoadMasonry,
      handleRenderListEmpty,
      handleSelectRule,
      refreshDate,
      select,
      selectMode,
      stateCreationData,
    ],
  )

  const handleRenderGroup = useMemo(
    () =>
      (creationType === 'all' || creationType === 'available') && (
        <TemplateProfileCreationGroup
          loadData={handleLoadGroup}
          refresh={refreshDate}
          onClickItem={handleClickDateGroup}
          search={creationType}
          toggleSelect={selectMode}
          stateSelected={stateSelectGroup}
          stateContentType={stateContentType}
          key={creationType}
          stateData={stateGroupData}
          scrollbar={false}
          listEmptyElement={handleRenderListEmpty}
          anchorExtractor={(item) => item.date}
        />
      ),
    [
      creationType,
      handleClickDateGroup,
      handleLoadGroup,
      handleRenderListEmpty,
      refreshDate,
      selectMode,
      stateContentType,
      stateGroupData,
      stateSelectGroup,
    ],
  )

  const handleRenderFloatingBtn = useMemo(
    () =>
      creationType === 'resend' &&
      data.length > 0 && (
        <StyledFloatingBtnContainer
          onClick={() => {
            setResendButtonType('continueResend')
            setSelectMode(true)
          }}>
          {resendButtonType === 'startSelectResend' ? (
            <StyledButton
              backgroundColor="white_2"
              color="primary_5"
              label={translate('giftShop:batchResend')}
              leftIcon={
                <StyledIconContainer>
                  <Icon
                    type="upload"
                    color="primary_5"
                    size={convertUnit(16)}
                  />
                </StyledIconContainer>
              }
            />
          ) : (
            <Button
              disabled={select.length === 0}
              label={translate('global:continue')}
              onClick={() =>
                history.push(
                  'giftshop_profile_resend_batch',
                  {
                    data: select,
                  },
                  username,
                )
              }
            />
          )}
        </StyledFloatingBtnContainer>
      ),
    [
      creationType,
      data.length,
      history,
      select,
      setSelectMode,
      setResendButtonType,
      resendButtonType,
      translate,
      username,
    ],
  )

  useEffect(() => {
    if (creationType === 'resend') {
      setResendButtonType(selectMode ? 'continueResend' : 'startSelectResend')
    }
  }, [creationType, selectMode, setResendButtonType, setSelectMode])

  useDidMount(handleGetResendCount)

  return (
    <StyledListContainer>
      {handleRenderUploadKYCModal}
      {handleRenderFloatingBtn}
      {handleRenderHeader}
      {handleRenderMasonry}
      {handleRenderGroup}
      {handleRenderButton}
    </StyledListContainer>
  )
}
