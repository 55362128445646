import React, {useMemo} from 'react'
import {Layer, Source} from 'react-map-gl'
import {AnyPaint} from 'mapbox-gl'
import {useTheme} from 'themes'
import {
  TREE_MAP_LAYER_ID_ACTIVE,
  TREE_MAP_LAYER_LAYOUT,
  TREE_MAP_LAYER_LAYOUT_FOTOTREE,
} from 'consts'
import {TreeActiveMarkerProps} from './TreeFototreeModalProps'

export default function TreeActiveMarker({featureData}: TreeActiveMarkerProps) {
  const theme = useTheme()

  const mapStylePaint = useMemo<AnyPaint>(
    () => ({
      'text-color': [
        'match',
        ['get', 'category'],
        'event',
        theme.danger_5,
        'community',
        theme.success_7,
        theme.primary_5,
      ],
      'text-halo-width': 1,
      'text-halo-color': [
        'match',
        ['get', 'category'],
        'event',
        theme.danger_1,
        'community',
        theme.success_1,
        theme.primary_1,
      ],
    }),
    [
      theme.danger_1,
      theme.danger_5,
      theme.primary_1,
      theme.primary_5,
      theme.success_1,
      theme.success_7,
    ],
  )
  const handleRenderActiveMarker = useMemo(
    () => (
      <Source id="map-active-source" type="geojson" data={featureData}>
        <Layer
          id={TREE_MAP_LAYER_ID_ACTIVE}
          type="symbol"
          layout={{
            ...TREE_MAP_LAYER_LAYOUT,
            ...TREE_MAP_LAYER_LAYOUT_FOTOTREE,
            'icon-size': 0.4,
            'text-field': ['get', 'name'],
            'text-size': 14,
            'text-font': ['Ubuntu Bold'],
          }}
          paint={mapStylePaint}
        />
      </Source>
    ),
    [featureData, mapStylePaint],
  )

  return handleRenderActiveMarker
}
