import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import base64 from 'base-64'
import {requestData} from 'services'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Avatar, Button, ButtonOutline, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'

const StyledOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: ${convertUnit(325)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
    padding: ${convertUnit(25)};
  }
`

const StyledDescription = styled.div`
  margin-top: ${convertUnit(10)};
  margin-bottom: ${convertUnit(15)};
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${convertUnit(170)};
  gap: ${convertUnit(8)};
`

const StyledButtonOutline = styled(ButtonOutline)`
  height: ${convertUnit(32)};
  white-space: nowrap;
  overflow: hidden;
`
const StyledButton = styled(Button)`
  height: ${convertUnit(32)};
`
const StyledLastSavedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${convertUnit(15)};
  gap: ${convertUnit(8)};
  justify-content: center;
  align-items: center;
`

export default function TemplateAuthAccessFullPage() {
  const {translate} = useTranslation()
  const history = useHistory()
  const {username, photo, refreshToken} = useSelector('lastUserState') || {}
  const [loading, setLoading] = useState(false)

  const handlePressLogin = useCallback(() => {
    const user = 'web'
    const password = process.env.AUTH_CLIENT_PASSWORD
    if (refreshToken) {
      setLoading(true)
      requestData('auth_refresh_token', {
        data: {
          code_verifier: process.env.AUTH_CODE_VERIFIER,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        },
        headers: {
          Authorization: `Basic ${base64.encode(`${user}:${password}`)}`,
        },
        onRequestReceived: () => setLoading(false),
      })
    }
  }, [refreshToken])

  const handleRenderSavedLogin = useMemo(
    () =>
      username &&
      photo &&
      refreshToken && (
        <StyledLastSavedContainer>
          <Avatar src={photo} alt={photo} size={72} />
          <StyledButtonOutline
            borderColor="gray_5"
            color="gray_5"
            label={translate('auth:loginAs', {username})}
            onClick={handlePressLogin}
            disabled={loading}
          />
        </StyledLastSavedContainer>
      ),
    [handlePressLogin, loading, photo, refreshToken, translate, username],
  )

  return (
    <StyledOuterContainer>
      <StyledContainer>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('forkygram:authAccessHeader')}
        </Paragraph>

        <StyledDescription>
          <Paragraph fontSize="s" color="gray_5">
            {translate('forkygram:authAccessDescription')}
          </Paragraph>
        </StyledDescription>
        {handleRenderSavedLogin}
        <StyledButtonsContainer>
          <StyledButtonOutline
            borderColor="gray_5"
            label={translate('global:signUp')}
            color="gray_5"
            onClick={() => history.push('auth_easy_signup', {})}
          />
          <StyledButton
            label={translate('global:login')}
            onClick={() => history.push('auth_login', {})}
            data-cy="TemplateAuthAccessLoginButton"
          />
        </StyledButtonsContainer>
      </StyledContainer>
    </StyledOuterContainer>
  )
}
