import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import AvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'
import exifr from 'exifr'
import {useTranslation} from 'i18n'
import {
  FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
  FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE,
  GIFT_SHOP_UPLOAD_BACKGROUND_MIN_HEIGHT,
  GIFT_SHOP_UPLOAD_BACKGROUND_MIN_WIDTH,
  GIFT_SHOP_UPLOAD_PROFILE_BACKGROUND_TYPE,
  GIFT_SHOP_UPLOAD_PROFILE_MAX_RESOLUTION,
  GIFT_SHOP_UPLOAD_PROFILE_MAX_SIZE,
  GIFT_SHOP_UPLOAD_TYPE,
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {
  GiftShopEditProfileModalTypes,
  GiftshopProfileVerifyPassword,
  ImageExif,
  ProfileEditPhotoUploadLinkParam,
  SocialLink,
  WindowModeType,
} from 'types'
import {useWindowMode} from 'windows'
import {requestData} from 'services'
import {
  cropImage,
  showSnackbar,
  useDidMount,
  getBoxShadow,
  useHistory,
  showModalReprocessKyc,
  getAndSupplyFileMime,
  handleUploadSingleFileToWasabi,
} from 'utils'
import {
  Button,
  ConfirmationModal,
  Icon,
  Image,
  ImageCropper,
  ModalLoading,
  Paragraph,
  Separator,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {convertImage} from 'utils/lib/heic2any/heic2anyHelper'
import {TemplateProfilePhotoModal} from 'pages/template'
import {GiftShopEditProfileContentProps} from './GiftShopEditProfileContentProps'
import {
  GiftShopProfileInformationContentItem,
  GiftShopProfileSocialContentItem,
} from '../ContentItem'
import {
  GiftShopEditProfileAddSocialLinkModal,
  GiftShopEditProfileImageOptionsModal,
  GiftShopEditProfilePasswordVerifyModal,
  GiftShopEditProfileUnverifiedModal,
} from '../Modal'

interface StyledIconContainerProps {
  isBackground: boolean
}

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  flex-direction: column;
  gap: ${convertUnit(20)};
  height: max-content;
  width: 100%;
`

const StyledHeaderContainer = styled.div`
  height: ${convertUnit(200)};
  width: 100%;
  position: relative;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: ${convertUnit(150)};
  }
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(130)};
  }
  align-items: center;
  justify-content: center;
`

const StyledBackgroundPhotoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const StyledBackground = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  background-size: cover;
  position: absolute;
  object-position: center;
  z-index: 0;
`

const StyledBackgroundPlaceholder = styled.div`
  background-color: ${({theme}) => theme.gray_8};
  height: ${convertUnit(200)};
  width: 100%;
  display: flex;
`

const StyledProfilePictureContainer = styled.div<StyledContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: ${convertUnit(130)};
  ${({mode}) => ({
    top: mode === 'mobile' ? convertUnit(70) : convertUnit(130),
  })};
`

const StyledProfilePicture = styled(Image)`
  width: ${convertUnit(104)};
  height: ${convertUnit(133)};
  border-radius: ${convertUnit(8)};
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  border: ${convertUnit(2)} solid ${({theme}) => theme.white_1};
  background-color: ${({theme}) => theme.black};
`

const StyledOverlayContainer = styled.div`
  width: ${convertUnit(119)};
  display: flex;
  position: absolute;
  z-index: 1;
`

const StyledIconContainer = styled.div<StyledIconContainerProps>`
  width: ${convertUnit(36)};
  background-color: ${({theme}) => theme.white_3};
  border-radius: ${convertUnit(50)};
  aspect-ratio: 1;
  z-index: 2;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  ${({isBackground}) => ({
    marginLeft: isBackground ? 0 : convertUnit(3),
    right: isBackground ? convertUnit(20) : 0,
    bottom: isBackground ? convertUnit(20) : 0,
  })}
  box-shadow: ${({theme}) => getBoxShadow(theme, {verticalOffset: -4})};
`

const StyledInfoContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-wrap: wrap;
  margin: ${convertUnit(-10)};
  ${({mode}) => ({
    paddingLeft: mode === 'mobile' ? convertUnit(20) : convertUnit(120),
    paddingRight: mode === 'mobile' ? convertUnit(20) : convertUnit(120),
    marginTop: mode === 'mobile' ? convertUnit(110) : convertUnit(90),
  })};
`

const StyledImageUpload = styled.input`
  display: none;
`

const StyledBackgroundUpload = styled.input`
  display: none;
`

const StyledChangePasswordContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`

const StyledChangePassword = styled(Paragraph)<StyledContainerProps>`
  cursor: pointer;
  flex-shrink: 0;
  ${({mode}) => ({
    paddingLeft: mode === 'mobile' ? convertUnit(25) : convertUnit(120),
    paddingRight: mode === 'mobile' ? convertUnit(25) : convertUnit(120),
  })};
`

const StyledDisclaimerContainer = styled.div<StyledContainerProps>`
  background-color: ${({theme}) => theme.white_3};
  border-radius: ${convertUnit(8)};
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  gap: ${convertUnit(16)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_3};
  ${({mode}) => ({
    marginLeft: mode === 'mobile' ? convertUnit(25) : convertUnit(120),
    marginRight: mode === 'mobile' ? convertUnit(25) : convertUnit(120),
  })};
`

const StyledSocialLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

const StyledSeparatorContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({mode}) => ({
    paddingLeft: mode === 'mobile' ? convertUnit(20) : convertUnit(120),
    paddingRight: mode === 'mobile' ? convertUnit(20) : convertUnit(120),
  })};
`

const StyledSocialHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  gap: ${convertUnit(4)};
  justify-content: space-between;
`

const StyledSocialHeaderInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const StyledSocialLinkContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: ${convertUnit(-4)};
  flex-wrap: wrap;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertUnit(12)};
  text-align: center;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_3};
`

const StyledButton = styled(Button)`
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: auto;
  }
`

export default function GiftShopEditProfileContent({
  stateUserInfo,
  stateActiveContent,
  stateShouldUpdate,
  statePhoneModal,
  stateLoading,
}: GiftShopEditProfileContentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const userInfo = stateUserInfo[0]
  const setActiveContent = stateActiveContent[1]
  const inputImageRef = useRef<HTMLInputElement>(null)
  const inputBackgroundImageRef = useRef<HTMLInputElement>(null)
  const {creatorStatus} = useSelector('yuserActivationState')
  const [modal, setModal] = useState<
    GiftShopEditProfileModalTypes | undefined
  >()
  const isLoading = stateLoading[0]
  const defaultPic = IMAGE_ASSET('giftshop', 'users/default-portrait.png')
  const [crop, setCrop] = useState(false)
  const [cropBackground, setCropBackground] = useState(false)
  const [background, setBackground] = useState(false)
  const [tempImage, setTempImage] = useState<File>()
  const ext = useRef('')
  const canvas = useRef<AvatarEditor>(null)
  const userData = useSelector('user')
  const {email: newEmail} = useSelector('userNewEmail') || {}
  const {phoneCode: newPhoneCode, phoneNumber: newPhoneNumber} =
    useSelector('userNewPhone') || {}
  const {isPhoneVerified} = useSelector('verificationStatus')
  const dateOfBirth = userData?.date_of_birth
  const [dobIsDisabled, setDobIsDisabled] = useState(false)
  const setShouldUpdate = stateShouldUpdate[1]
  const selectedItemRef = useRef<SocialLink | null>(null)
  const mode = useWindowMode()
  const [emailVerifyModal, setEmailVerifyModal] = useState(false)
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false)
  const [passwordVerifyModal, setPasswordVerifyModal] = useState<{
    visible: boolean
    item: GiftshopProfileVerifyPassword
  }>({
    visible: false,
    item: 'none',
  })
  const setPhoneModal = statePhoneModal[1]
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus, similarStatus])

  const handleCheckDOB = useCallback(() => {
    if (creatorStatus !== 'verified') {
      requestData('giftshop_get_date_of_birth', {
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setDobIsDisabled(!result.is_allowed)
          } else {
            setDobIsDisabled(false)
          }
        },
      })
    } else {
      setDobIsDisabled(true)
    }
  }, [creatorStatus])

  const handleBioChange = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    setActiveContent('bio')
  }, [setActiveContent, kycStatus, handleFeatureDisabled])

  const handlePasswordChange = useCallback(() => setActiveContent('password'), [
    setActiveContent,
  ])

  const handlePasswordVerifyModal = useCallback(
    (item: GiftshopProfileVerifyPassword) =>
      setPasswordVerifyModal((prev) => ({visible: !prev.visible, item})),
    [],
  )

  const handleEmailVerifyModal = useCallback(() => {
    setEmailVerifyModal((prev) => !prev)
  }, [])

  const handlePhoneVerifyModal = useCallback(() => {
    setPhoneVerifyModal((prev) => !prev)
  }, [])

  const handleNicknameChange = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    if (!userInfo.hasPassword) {
      handlePasswordVerifyModal('nickname')
      return
    }
    setActiveContent('nickname')
  }, [
    kycStatus,
    userInfo.hasPassword,
    setActiveContent,
    handleFeatureDisabled,
    handlePasswordVerifyModal,
  ])

  const handleUsernameChange = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    if (!userInfo.hasPassword) {
      handlePasswordVerifyModal('username')
      return
    }
    setActiveContent('username')
  }, [
    kycStatus,
    userInfo.hasPassword,
    setActiveContent,
    handleFeatureDisabled,
    handlePasswordVerifyModal,
  ])

  const handleEmailChange = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    if (!userInfo.hasPassword) {
      handlePasswordVerifyModal('email')
    } else if (newEmail) {
      handleEmailVerifyModal()
    } else {
      setActiveContent('email')
    }
  }, [
    handleEmailVerifyModal,
    handlePasswordVerifyModal,
    newEmail,
    setActiveContent,
    userInfo.hasPassword,
    kycStatus,
    handleFeatureDisabled,
  ])

  const handleNumberChange = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    if (!userInfo.hasPassword) {
      handlePasswordVerifyModal('phone')
    } else if (newPhoneNumber) {
      handlePhoneVerifyModal()
    } else setActiveContent('phone')
  }, [
    handlePasswordVerifyModal,
    handlePhoneVerifyModal,
    newPhoneNumber,
    setActiveContent,
    userInfo.hasPassword,
    kycStatus,
    handleFeatureDisabled,
  ])

  const handleDoBChange = useCallback(() => {
    if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
      handleFeatureDisabled()
      return
    }
    setActiveContent('date_of_birth')
  }, [setActiveContent, kycStatus, handleFeatureDisabled])

  const handleImageChange = useCallback(
    async (img: File) => {
      const exif = (await exifr.parse(img)) as ImageExif
      const {ImageHeight, ImageWidth} = exif
      if (background && ImageWidth < GIFT_SHOP_UPLOAD_BACKGROUND_MIN_WIDTH) {
        showSnackbar(
          translate('giftShop:editProfileBackgroundSize', {
            height: GIFT_SHOP_UPLOAD_BACKGROUND_MIN_HEIGHT,
            width: GIFT_SHOP_UPLOAD_BACKGROUND_MIN_WIDTH,
          }),
        )
        setModal(undefined)
        return
      }
      const data: ProfileEditPhotoUploadLinkParam = {
        extension: ext.current.split('/')[1],
        height: ImageHeight,
        width: ImageWidth,
        size: img.size,
      }
      const response = await requestData(
        background
          ? 'member_upload_background_photo_link'
          : 'member_upload_profile_photo_link',
        {
          data,
        },
      )
      if (typeof response !== 'string') {
        if (response.status === 200) {
          const {link} = response.data.result
          const isSuccess = await handleUploadSingleFileToWasabi(
            img,
            link,
            ext.current,
            {
              'Content-Type': ext.current,
              'x-amz-meta-imagewidth': ImageWidth.toString(),
              'x-amz-meta-imageheight': ImageHeight.toString(),
            },
          )
          if (isSuccess) {
            requestData(
              background
                ? 'member_upload_background_photo'
                : 'member_upload_profile_photo',
              {
                data: {exif: JSON.stringify(exif), link, size: img.size},
                onRequestReceived: () => setModal(undefined),
                onRequestSuccess: ({status}) => {
                  if (status === 200) {
                    setShouldUpdate({})
                  } else {
                    showSnackbar(
                      translate('global:messageError', {
                        context: `code-${status}`,
                      }),
                    )
                  }
                },
              },
            )
          } else {
            setModal(undefined)
            showSnackbar(translate('forkygram:uploadInvalidImageFormat'))
          }
        } else {
          setModal(undefined)
          showSnackbar(
            translate('global:messageError', {
              context: `code-${response.status}`,
            }),
          )
        }
      }
    },
    [background, setShouldUpdate, translate],
  )

  const handleCrop = useCallback(
    (image?: File) => {
      setModal('loading')
      if (image) {
        if (background) {
          setCropBackground(false)
        } else {
          setCrop(false)
        }
        handleImageChange(image)
      }
    },
    [background, handleImageChange],
  )

  const handleCheckImage = useCallback(
    (
      inputRef: RefObject<HTMLInputElement>,
      setter: Dispatch<SetStateAction<boolean>>,
    ) => {
      setModal('loading')
      if (inputRef.current?.files?.length) {
        let file = inputRef.current?.files[0]
        let type = getAndSupplyFileMime(GIFT_SHOP_UPLOAD_TYPE, file)

        if (!GIFT_SHOP_UPLOAD_TYPE.includes(type)) {
          showSnackbar(translate('giftShop:invalidFormatData'))
          setModal(undefined)
          return
        }
        if (file.size > GIFT_SHOP_UPLOAD_PROFILE_MAX_SIZE) {
          showSnackbar(translate('giftShop:editProfileMaxFileSize'))
          setModal(undefined)
          return
        }

        const isSpecialImageType = FORKYGRAM_SPECIAL_IMAGE_UPLOAD_TYPE.includes(
          type,
        )

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
          const url = reader.result?.toString()

          if (!url) return

          if (
            url.includes('image') ||
            (type.includes('image') && isSpecialImageType)
          ) {
            const img = document.createElement('img')

            if (isSpecialImageType) {
              type = FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE

              const {file: newImage, blob} = await convertImage(
                url,
                FORKYGRAM_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE,
                file.name,
              )
              img.src = URL.createObjectURL(blob)
              file = newImage
            } else {
              img.src = url
            }

            ext.current = type

            img.onload = () => {
              const {height, width} = img
              if (height * width > GIFT_SHOP_UPLOAD_PROFILE_MAX_RESOLUTION) {
                showSnackbar(translate('giftShop:editProfileMaxResolution'))
                setModal(undefined)
                return
              }
              setTempImage(file)
              setModal(undefined)
              setter(true)
            }
          }
        }
      }
    },
    [translate],
  )

  const handleRemoveImage = useCallback(() => {
    setModal(undefined)
    requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      headerContentType: 'multipart/form-data',
      dataType: 'form-data',
      data: {remove_photo: true, bio: userData?.biodata},
      onRequestSuccess: ({status}) => {
        status === 200 && setShouldUpdate({})
      },
    })
  }, [setShouldUpdate, userData?.biodata])

  const onSocialContentItemClick = useCallback((item: SocialLink) => {
    setModal('socialLinkDeletion')
    selectedItemRef.current = {
      social_type: item.social_type,
      social_value: item.social_value,
    }
  }, [])

  const handleRemoveBackground = useCallback(() => {
    setModal(undefined)
    requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      headerContentType: 'multipart/form-data',
      dataType: 'form-data',
      data: {remove_background_photo: true, bio: userData?.biodata},
      onRequestSuccess: ({status}) => {
        status === 200 && location.reload()
      },
    })
  }, [userData?.biodata])

  const handleRenderBackground = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledBackgroundPhotoContainer>
          {userInfo.background_photo ? (
            <StyledBackground
              width="100%"
              height="100%"
              useLazyLoad
              src={userInfo.background_photo}
              alt={`background-picture ${userInfo.username}`}
            />
          ) : (
            <StyledBackgroundPlaceholder />
          )}
        </StyledBackgroundPhotoContainer>
        <StyledIconContainer
          isBackground
          onClick={() => {
            setBackground(true)
            setModal('imageOptions')
          }}>
          <Icon type="pencil" size={convertUnit(16)} color="primary_5" />
        </StyledIconContainer>
        <StyledProfilePictureContainer mode={mode}>
          <StyledOverlayContainer>
            <StyledProfilePicture
              useLazyLoad
              src={userInfo.photo}
              fallbackSrc={defaultPic}
              alt={`profile-picture ${userInfo.username}`}
              onClick={() => !isLoading && setModal('imagePreview')}
            />
            <StyledIconContainer
              isBackground={false}
              onClick={() => {
                setBackground(false)
                setModal('imageOptions')
              }}>
              <Icon type="pencil" size={convertUnit(16)} color="primary_5" />
            </StyledIconContainer>
            <StyledImageUpload
              type="file"
              ref={inputImageRef}
              accept={GIFT_SHOP_UPLOAD_TYPE.join(',')}
              onChange={() => handleCheckImage(inputImageRef, setCrop)}
              onClick={() => {
                inputImageRef.current && (inputImageRef.current.value = '')
              }}
            />
            <StyledBackgroundUpload
              type="file"
              ref={inputBackgroundImageRef}
              accept={GIFT_SHOP_UPLOAD_PROFILE_BACKGROUND_TYPE.join(',')}
              onChange={() =>
                handleCheckImage(inputBackgroundImageRef, setCropBackground)
              }
              onClick={() => {
                inputBackgroundImageRef.current &&
                  (inputBackgroundImageRef.current.value = '')
              }}
            />
          </StyledOverlayContainer>
        </StyledProfilePictureContainer>
      </StyledHeaderContainer>
    ),
    [defaultPic, handleCheckImage, isLoading, mode, userInfo],
  )

  const handleRenderRemoveImageModal = useMemo(
    () => (
      <ConfirmationModal
        visible
        title={translate('giftShop:removePicture')}
        text={translate('giftShop:removeProfilePictureModalDesc')}
        confirmText={translate('global:remove')}
        cancelText={translate('global:cancel')}
        onConfirm={background ? handleRemoveBackground : handleRemoveImage}
        toggleModal={() => setModal('imageOptions')}
      />
    ),
    [background, handleRemoveBackground, handleRemoveImage, translate],
  )

  const handleRenderEditImageOptionsModal = useMemo(
    () => (
      <GiftShopEditProfileImageOptionsModal
        visible
        defaultPic={
          background
            ? userInfo.background_photo === ''
            : userInfo.photo === defaultPic
        }
        onClickEdit={() => {
          background
            ? inputBackgroundImageRef.current &&
              inputBackgroundImageRef.current.click()
            : inputImageRef.current && inputImageRef.current.click()
        }}
        onClickRemove={() => setModal('imageRemoval')}
        onClickClose={() => setModal(undefined)}
      />
    ),
    [background, defaultPic, userInfo],
  )

  const handleRenderProfilePhotoModal = useMemo(
    () => (
      <TemplateProfilePhotoModal
        src={userInfo.photo}
        toggleModal={() => setModal(undefined)}
      />
    ),
    [userInfo.photo],
  )

  const handleRenderPasswordVerifyModal = useMemo(
    () => (
      <GiftShopEditProfilePasswordVerifyModal
        visible={passwordVerifyModal.visible}
        item={passwordVerifyModal.item}
        onCancel={() => handlePasswordVerifyModal('none')}
        onConfirm={handlePasswordChange}
      />
    ),
    [handlePasswordChange, handlePasswordVerifyModal, passwordVerifyModal],
  )

  const handleRenderEmailVerifyModal = useMemo(
    () => (
      <GiftShopEditProfileUnverifiedModal
        visible={emailVerifyModal}
        item="email"
        onAddNew={() => setActiveContent('email')}
        onCancel={handleEmailVerifyModal}
        onConfirm={() =>
          history.push('auth_verification_email', {email: newEmail})
        }
      />
    ),
    [
      emailVerifyModal,
      handleEmailVerifyModal,
      history,
      newEmail,
      setActiveContent,
    ],
  )

  const handleRenderPhoneVerifyModal = useMemo(
    () => (
      <GiftShopEditProfileUnverifiedModal
        visible={phoneVerifyModal}
        item="phone"
        onAddNew={() => setActiveContent('phone')}
        onCancel={handlePhoneVerifyModal}
        onConfirm={() => {
          setActiveContent('phone')
          setPhoneModal('verify')
        }}
      />
    ),
    [handlePhoneVerifyModal, phoneVerifyModal, setActiveContent, setPhoneModal],
  )

  const handleRenderAddSocialLinkModal = useMemo(
    () => (
      <GiftShopEditProfileAddSocialLinkModal
        stateShouldUpdate={stateShouldUpdate}
        onClickClose={() => setModal(undefined)}
      />
    ),
    [stateShouldUpdate],
  )

  const handleRenderDeleteSocialLinkModal = useMemo(
    () => (
      <GiftShopEditProfileAddSocialLinkModal
        isEdit
        selectedItemRef={selectedItemRef || undefined}
        stateShouldUpdate={stateShouldUpdate}
        onClickClose={() => setModal(undefined)}
      />
    ),
    [stateShouldUpdate],
  )

  const handleRenderCropperModal = useMemo(
    () =>
      tempImage && userData?.username ? (
        <ImageCropper
          shape="portrait"
          visible={crop}
          border={0}
          borderRadius={0}
          componentRef={canvas}
          file={tempImage}
          onBackdropPress={() => {
            setTempImage(undefined)
            setCrop(false)
          }}
          onClickSave={() => cropImage(canvas, userData.username, handleCrop)}
        />
      ) : null,
    [crop, handleCrop, tempImage, userData?.username],
  )

  const handleRenderCropperBackgroundModal = useMemo(
    () =>
      tempImage && userData?.username ? (
        <ImageCropper
          shape="landscape"
          visible={cropBackground}
          border={0}
          borderRadius={0}
          componentRef={canvas}
          file={tempImage}
          onBackdropPress={() => {
            setTempImage(undefined)
            setCropBackground(false)
          }}
          onClickSave={() => cropImage(canvas, userData.username, handleCrop)}
        />
      ) : null,
    [cropBackground, handleCrop, tempImage, userData?.username],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'imageOptions':
        return handleRenderEditImageOptionsModal
      case 'imagePreview':
        return handleRenderProfilePhotoModal
      case 'imageRemoval':
        return handleRenderRemoveImageModal
      case 'loading':
        return <ModalLoading visible />
      case 'socialLinkCreation':
        return handleRenderAddSocialLinkModal
      case 'socialLinkDeletion':
        return handleRenderDeleteSocialLinkModal
      default:
        return null
    }
  }, [
    handleRenderAddSocialLinkModal,
    handleRenderDeleteSocialLinkModal,
    handleRenderEditImageOptionsModal,
    handleRenderProfilePhotoModal,
    handleRenderRemoveImageModal,
    modal,
  ])

  useDidMount(handleCheckDOB)

  return (
    <>
      {handleRenderModal}
      {handleRenderCropperModal}
      {handleRenderCropperBackgroundModal}
      {handleRenderPasswordVerifyModal}
      {handleRenderEmailVerifyModal}
      {handleRenderPhoneVerifyModal}
      <StyledScreenContainer>
        {handleRenderBackground}
        <StyledInfoContainer mode={mode}>
          <GiftShopProfileInformationContentItem
            badge
            disabled={isLoading}
            label={translate('global:username')}
            actionText={translate('global:change')}
            value={userInfo.username}
            onAction={handleUsernameChange}
          />
          <GiftShopProfileInformationContentItem
            disabled={isLoading}
            label={translate('global:nickname')}
            actionText={translate('global:change')}
            value={userInfo.nickname}
            onAction={handleNicknameChange}
          />
          <GiftShopProfileInformationContentItem
            label={translate('global:email')}
            value={newEmail || userInfo.email}
            actionText={
              userInfo.email
                ? translate('global:change')
                : translate('global:add')
            }
            disabled={isLoading}
            onAction={handleEmailChange}
          />
          <GiftShopProfileInformationContentItem
            label={translate('global:mobileNumber')}
            value={
              newPhoneCode && newPhoneNumber
                ? `+${newPhoneCode} ${newPhoneNumber}`
                : userInfo.mobileNumber
            }
            actionText={
              userInfo.mobileNumber
                ? translate('global:change')
                : translate('global:add')
            }
            showUnconfirmedLabel={
              newPhoneCode && newPhoneNumber
                ? !!newPhoneNumber && !isPhoneVerified
                : !!userInfo.mobileNumber && !isPhoneVerified
            }
            disabled={isLoading}
            onAction={handleNumberChange}
          />
          <GiftShopProfileInformationContentItem
            label={translate('global:dateOfBirth')}
            value={dateOfBirth !== '//' ? dateOfBirth : undefined}
            actionText={translate('global:change')}
            onAction={handleDoBChange}
            disabled={dobIsDisabled}
          />
          <GiftShopProfileInformationContentItem
            label={translate('giftShop:bio')}
            value={userInfo.bio}
            actionText={
              userInfo.bio
                ? translate('global:change')
                : translate('global:add')
            }
            disabled={isLoading}
            onAction={handleBioChange}
            emptyText={translate('giftShop:noBio')}
          />
        </StyledInfoContainer>
        <StyledChangePasswordContainer>
          <StyledChangePassword
            mode={mode}
            fontSize="m"
            fontWeight="medium"
            color="primary_5"
            onClick={handlePasswordChange}>
            {translate('giftShop:managePasswordHeader')}
          </StyledChangePassword>
        </StyledChangePasswordContainer>

        <StyledDisclaimerContainer mode={mode}>
          <Icon type="info" size={convertUnit(16)} />
          <Paragraph fontSize="m" fontWeight="medium" lineHeight={21}>
            {translate('giftShop:editProfileDisclaimer')}
          </Paragraph>
        </StyledDisclaimerContainer>

        <StyledSeparatorContainer mode={mode}>
          <Separator horizontalRule="top">
            <StyledSocialLinkContainer>
              <StyledSocialHeaderContainer>
                <StyledSocialHeaderInfoContainer>
                  <Paragraph fontSize="m" fontWeight="medium">
                    {translate('giftShop:socialLink')}
                  </Paragraph>
                  <Paragraph color="gray_4">
                    {translate('giftShop:socialLinkDescription')}
                  </Paragraph>
                </StyledSocialHeaderInfoContainer>
                <StyledButton
                  disabled={userInfo.social.length === 6}
                  onClick={() =>
                    !(
                      kycStatus === 'verified' || kycStatus === 'verified_check'
                    )
                      ? handleFeatureDisabled()
                      : setModal('socialLinkCreation')
                  }
                  label={translate('global:add')}
                />
              </StyledSocialHeaderContainer>
              <StyledSocialLinkContentContainer>
                {userInfo.social ? (
                  userInfo.social.map((item) => (
                    <GiftShopProfileSocialContentItem
                      key={item.social_type}
                      item={item}
                      onClickItem={onSocialContentItemClick}
                    />
                  ))
                ) : (
                  <StyledEmptyContainer>
                    <Paragraph fontSize="m" fontWeight="medium" color="gray_3">
                      {translate('giftShop:socialLinkDescriptionEmpty')}
                    </Paragraph>
                  </StyledEmptyContainer>
                )}
              </StyledSocialLinkContentContainer>
            </StyledSocialLinkContainer>
          </Separator>
        </StyledSeparatorContainer>
      </StyledScreenContainer>
    </>
  )
}
